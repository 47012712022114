import React, { useEffect, useState} from 'react';
import { useAlert } from 'react-alert'
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import BottomSheet from '../components/BottomSheet';
import Countdown from 'react-countdown';
import LinesEllipsis from 'react-lines-ellipsis';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Whistlists = () => {
  const currentCompany = ReactSession.get("current_company");
  const alert = useAlert();
  const params = new URLSearchParams(window.location.search);
  const current_order_count = ReactSession.get("current_order_count");

  const token = ReactSession.get("token");
  const currentUserLogin = ReactSession.get("current_user");
  const history = useHistory();

  const [all, setAll] = useState(true);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [promoId, setPromoId] = useState('');

  const [currentUser, setCurrentUser] = useState({});
  const [products, setProducts] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'company, websetting_galleries'}};

    API.get(`sessions`, config )
      .then(res => {
        const data = res.data.data;
        setCurrentUser(data);
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }
        console.log(error.response);
      })
    
    const config_product = { headers: { Authorization: `Bearer ${token}`}, params: { include: 'product, product_prices, product_variants' } };
    API.get(`whistlists`, config_product)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setProducts(data)
        setSpinner(false)
        setLoadMore(false);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, include: 'product, product_prices, product_variants' } };
    
    API.get(`whistlists`, config)
      .then(res => {
        setLoadMore(false);
        const data = res.data.data;
        setProducts([...products, ...data]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const changeFavorited = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.post(`whistlists`, {product_id: id}, config )
      .then(res => {
        const data = res.data.data;
        loadData();
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  
  useEffect(() => {
    if(token === undefined || currentUserLogin === undefined){
      history.push("/login")
    }

    loadData();

    M.AutoInit();
    let elSide = document.querySelectorAll('.sidenav-overlay');
    let elBody = document.querySelectorAll('body');
    elSide.forEach((item: any) => {
      item.style.display = 'none';
    })
    elBody.forEach((item: any) => {
      item.style.overflow = 'auto';
    })
    
  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Whistlist</span>
          </div>
        </nav>
      </div>
      
      <div className="row m-top-10 m-bottom-0">
        { spinner ? (
            <div className="col s12 m12" >
              <center><Dots color='#444' /></center>
            </div>
          ) : (
            <>
              { products && products.length > 0 ? (
                <>
                  {
                    products.map((whistlist) => {
                      let p = whistlist.product
                      let price = p.sell_price;
                      let discount = 0;
                      
                      if(p.product_type == 'price_on_variant' && p.product_variants && p.product_variants.filter(o => o.parent_id === null).length > 0){
                        let varian_sku = p.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id)[0]
                        if(p.product_prices.length > 0){
                          let pPrice = p.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === varian_sku.id )[0]
                          if(pPrice !== undefined){
                            price = pPrice.sell_price;
                          }else{
                            price = varian_sku.sell_price;
                          }
                        }
                      }else{
                        if(p.product_prices > 0){
                          let pPrice = p.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === null )[0]
                          if(pPrice !== undefined){
                            price = pPrice.sell_price;
                          }
                        }
                      }

                      if(currentUser.group_user_id === null || currentUser.group_user_id === 0){
                        discount = parseInt(p.discount_customer)
                      }else{
                        discount = parseInt(p.discount)
                      }

                      return (
                        <div className="col s6 m6" key={p.id}>
                          <div className="card card-product vertical" style={{background: 'white'}}>
                            <div className="card-image">
                              {/* {p.subsidi > 0 ? (<div className='free-shipping'>GRATIS ONGKIR</div>) : null} */}
                              {/* {p.cashback > 0 ? (<div className='cashback' style={{ left: p.subsidi < 1 ? 0 : 75 }}>CASHBACK</div>) : null} */}
                              {discount > 0 ? (<div className='discount-label'>{parseInt(discount)}%<br/><span className='off-disc'>OFF</span></div>) : null}
                              {p.point > 0 ? (<div className='point-label'>{parseInt(p.point)}<br/><span className='off-disc'>POIN</span></div>) : null}
                              {p.stock < 1 ? (<div className='outstock-label'>STOK HABIS</div>) : null}
                              <div className='love-whistlist love-whistlist-catalog'>
                                <i className="material-icons" onClick={(e) => changeFavorited(p.id)}>favorite</i>
                              </div>
                              <Link to={'/product/'+p.id}><img src={p.image_url}/></Link>
                            </div>
                            <div className="card-content">
                              <Link to={'/product/'+p.id} className='title-card' style={{fontWeight: 500}}>
                                <LinesEllipsis
                                  text={p.title}
                                  maxLine='2'
                                  ellipsis='...'
                                  trimRight
                                  basedOn='letters'
                                />  
                              </Link>
                              <div className='category-card'>{p.category_name}</div>
                              <div className='price-card'>
                                {discount > 0 ? (<span className='discount-price'>{numberFormat(price)}</span>) : null} {numberFormat(parseInt(price)-(parseInt(price)*parseInt(discount)/100))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </>
              ) : (
                <div className='col s12 m12'>
                  <div className='blank-message'>
                    Data Tidak Ditemukan
                  </div>
                </div>
              )}

              {paging ? (
                <div className='col s12 m12'>
                  <div onClick={loadMoreData} className='load-more-btn' style={{background: '#fff'}}>
                    Load More
                    {loadMore ? (
                      <center><Dots color='#444' /></center>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          )
        }
      </div>
      <BottomSheet/>
    </>
    ) : null
  );
}

export default Whistlists;
