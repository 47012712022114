import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import {
  silverIcon,
  goldIcon,
  platinumIcon
} from '../assets'

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Welcome = () => {
  const {id} = useParams();
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const [orderItems, setOrderItems] = useState([]);
  const [company, setCompany] = useState({});
  const [paymentNumbers, setPaymentNumbers] = useState([]);
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [domain, setDomain] = useState('');
  const [user, setUser] = useState({});

  const loadData = () => {
    const config = { params: {include: 'payment_accounts'} };
    API.get(`get_company`, config )
      .then(res => {
        const data = res.data.data;
        setCompany(data);
        setPaymentNumbers(data.payment_accounts);
      })
  }

  function currencyFormat(num) {
    return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  useEffect(() => {
    setUser(ReactSession.get("register_member"))
    loadData();
  },[])

  return (
    company ? (
      <>
        <div className="navbar-fixed navbar-fixed-50">
          <nav className="nav-screen" style={{ background: (company.themeplate === 'App Theme 2' ? company.company_color2 : '' ) }}>
            <div className="nav-wrapper">
              <ul className="left">
                <li>
                  <Link to="/" ><i className="material-icons" style={{color: (company.themeplate === 'App Theme 2' ? 'white' : company.company_color1 ) }}>keyboard_backspace</i></Link>
                </li>
              </ul>
              <span style={{color: (company.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Verifikasi Pendaftaran</span>
            </div>
          </nav>
        </div>

        { spinnerScreen === false ? (
          <ScreenLoading/>
        ) : user ? (
          <>
            <div className='sidenav-overlay'></div>
            <div className="row m-bottom0">
              <div className="">
                <div className="row">
                  <div className="col s12 m12">
                    <div className='sub-order-total'> 
                      <br/><br/><br/>
                      <i className="material-icons" style={{color: (company.company_color1)}}>check_circle</i>
                      <br/>
                      Selamat {user.full_name} !
                      <br/>
                      <span className='total'>
                        Akun Anda <br/>Sudah Berhasil Didaftarkan!
                      </span>
                      <br/>
                      { user.last_package ? (
                          user.last_package.title.includes('Silver') ? (
                            <img src={silverIcon} width='100'/>
                          ) : user.last_package.title.includes('Gold') ? (
                            <img src={goldIcon} width='100'/>
                          ) : user.last_package.title.includes('Platinum') ? (
                            <img src={platinumIcon} width='100'/>
                          ) : null
                        ) : null 
                      }
                      <br/>
                      <span style={{fontSize:15}}>
                        <b>{user.last_package ? user.last_package.title : ''}</b>
                      </span>
                      <br/>
                      <span style={{fontSize:15}}>
                        Harga {user.register_member_price ? currencyFormat(user.register_member_price) : ''}
                      </span>
                      <br/>
                      {
                        user.last_package && !user.last_package.title.includes('Registrasi') ? (
                          <>
                            <small>(Harga Sudah Termasuk Pendaftaran Member & Gratis Paket Produk)</small>
                            <br/>
                          </>
                        ) : null
                      }
                      <small>Waktu Pendaftaran {user.created_at}</small>
                      <div className="row border-bottom">
                        <div className="col s12 m12">
                          <div className='box-bank-number'> 
                            <small>Silahkan Untuk Pembayaran Via Transfer Bisa Ke Norek Dibawah Ini, Lalu Konfirmasi Pembayaran Anda Ke Customer Service Kami :</small>
                            <br/><br/>
                            {
                              paymentNumbers.map((p) => {
                                return(
                                  <div className='list-bank-number'>
                                    <b>{p.name} {p.code}</b>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                      <div style={{textAlign:'left', marginTop: 20}}>
                        1. Silahkan melakukan pembayaran pendaftaran member.
                        <br/>
                        2. Lakukan konfirmasi ke admin dengan cara chat lewat hubungi customer service kami.
                        <br/>
                        3. Akun yang anda daftarkan akan diverifikasi terlebih dahulu oleh admin kami, silahkan tunggu maximal 1x24jam.
                        <br/>
                        4. Ketika aktif akun anda sudah bisa digunakan login diaplikasi ini.
                        <br/>
                        5. Ketika sudah login silahkan melakukan pembelian awal sesuai paket yang diinginkan.
                        <br/>
                        6. Jika ada pertanyaan silahkan hubungi kami segera.
                      </div>
                      <br/><br/>
                      <a href={'https://api.whatsapp.com/send?phone='+company.phone1+'&text=Saya%20mau%20aktifkan%20akun%20'+user.email+'%20'+(user.last_package ? user.last_package.title : '')} className='btn btn-chat' style={{background: (company.company_color2)}}><i className="material-icons left">chat</i> Hubungi Customer Service</a>
                    </div>
                  </div>
                </div>

                {/* <div className="row border-bottom">
                  <div className="col s12 m12">
                    <div className='box-bank-number' style={{textAlign: 'left'}}> 
                      <small>
                        1. Akun Yang Anda Buat Adalah Akun Dengan Tingkatan Customer Maksudnya Adalah Akun Ini Hanya Bisa Digunakan Untuk Belanja Dengan Harga Customer Yang Bakal Di Dapat Nantinya.
                        <br/><br/>
                        2. Untuk <b>Upgrade Ke Akun Mitra</b> Yang Resmi Anda Harus Melakukan Pembelian Awal Sample Produk Terlebih Dahulu Lewat Admin, Sehingga Nantinya Anda Akan Dapat Harga Mitra Yang Jauh Lebih Murah Dari Harga Customer Tentunya. Untuk Upgrade Ke Akun Mitra Resmi Silahkan Hub Admin Dengan Klik Tombol Button Upgrade Akun Dibawah Detail Akun.
                      </small>
                    </div>
                  </div>
                </div> */}

                {/* <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s12 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      <b>DETAIL AKUN</b>
                    </div>
                  </div>
                </div>

                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      ID
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.code}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Jenis Akun
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>Customer</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Nama Lengkap
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.full_name}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Username
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.username}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      No.HP/WA Aktif
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.phone1}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Alamat Email
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.email}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Tanggal Lahir
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.birthday}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Jenis Kelamin
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.gender}</b>
                    </div>  
                  </div>
                </div>
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                      Alamat Domisili
                    </div>
                    <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                      <b>{user.city}, {user.province}</b>
                    </div>  
                  </div>
                </div> */}
              </div>
            </div>
          </>
        ) : null }
      </>
    ) : null
  );
}

export default Welcome;

const styles: StyleSheet = {
  status: (status) => ({
    color: status === 'pending' ? 'orange' : (status === 'shipped' || status === 'received') ? 'green' : status === 'packing' ? '#11cdef' : 'red' ,
  }),
  status_payment: {
    fontSize: 11,
    color: 'orange',
    paddingBottom: 2,
    borderRadius: 5,
  }
}
