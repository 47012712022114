import React, { useEffect, useState, useParams } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import HeaderScreen from '../components/HeaderScreen'
import ScreenLoading from '../components/ScreenLoading'
import M from 'materialize-css';
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';

const EditProfile = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const alert = useAlert();
  const history = useHistory();
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [spinnerImg, setSpinnerImg] = useState(false);
  const [editAddress, setEditAddress] = useState(false);

  const [data, setData] = useState({});
  const [idCardNumber, setIdCardNumber] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [address, setAddress] = useState('');
  const [addressCode, setAddressCode] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [imageSrc, setImageSrc] = useState();
  const [instagram, setInstagram] = useState('');
  const [linkMarketplace1, setLinkMarketplace1] = useState('');
  const [idCardFile, setIdCardFile] = useState();
  const [idCardSrc, setIdCardSrc] = useState();

  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    
    const files = e.target.files[0]
    const fd = new FormData();
    fd.append('avatar', files, files.name);
    fd.append('include', 'company, websetting_galleries, last_package');
    if(idCardFile){
      fd.append('id_card', idCardFile, idCardFile.name);
    }

    setSpinnerImg(true);
    const config = {  headers: { Authorization: `Bearer ${token}` } };

    API.put(`users/${currentUser.id}/avatar`, fd, config )
      .then(res => {
        const data = res.data.data;
        ReactSession.set("current_user", data);
        setSpinnerImg(false);
        alert.success('Avatar Berhasil Diubah');
      })
      .catch(error => {
        setSpinnerImg(false);
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }

  const onChangeAddress = () => {
    editAddress === true ? setEditAddress(false) : setEditAddress(true)
  }

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`} };

    API.get(`sessions`, config )
      .then(res => {
        const data = res.data.data;
        setData(data);
        setIdCardNumber(data.id_card_number);
        setUsername(data.username);
        setEmail(data.email);
        setFullName(data.full_name);
        setGender(data.gender);
        setBirthday(data.birthday);
        setPhone1(data.phone1);
        setPhone2(data.phone2);
        setAddress(data.address);
        setAddressCode(data.address_code);
        setFullAddress(`${data.district}, ${data.city}, ${data.province}`)
        setDistricts([{id: data.address_code, name: data.district+','+data.city+','+data.province }])
        setInstagram(data.instagram);
        setLinkMarketplace1(data.link_marketplace1);
        setSpinnerScreen(false);
        setImageSrc(data.avatar_url)
        setIdCardSrc(data.id_card_url)
        let elems = document.getElementById("sel");
        let instances = window.M.FormSelect.init(elems, {});
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const changeProfile = () => {
    setSpinner(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(idCardFile){
      fd.append('id_card', idCardFile, idCardFile.name);
    }
    fd.append('id_card_number', idCardNumber);
    fd.append('username', username);
    fd.append('email', email);
    fd.append('full_name', fullName);
    fd.append('gender', gender);
    fd.append('birthday', birthday);
    fd.append('phone1', phone1);
    fd.append('phone2', phone2);
    fd.append('address', address);
    fd.append('address_code', addressCode);
 
    API.put(`user_app/${currentUser.id}`, fd, config )
      .then(res => {
        const data = res.data.data;
        ReactSession.set("current_user", data);
        setFullAddress(`${data.district}, ${data.city}, ${data.province}`)
        setSpinner(false);
        alert.success('Profil Berhasil Diubah')
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const handleIdCardSelect = (e) => {
    setIdCardSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setIdCardFile(files);
  }

  useEffect(() => {
    if(token === undefined || currentUser === undefined){
      history.push("/login")
    }
    
    loadData();

    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'none';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'auto';
    // })

    let elems = document.getElementById("sel");
    let instances = window.M.FormSelect.init(elems, {});

  }, []);

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/profile" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Pengaturan Profil</span>
          </div>
        </nav>
      </div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <div className="row">
          <div className="col s12">
            <div className="col s12">
              <div className='avatar-user'>
                <ImageUpload
                  handleImageSelect={handleImageSelect}
                  imageSrc={imageSrc}
                  setImageSrc={setImageSrc}
                  style={{
                    width: 120,
                    height: 120
                  }}
                />
                { spinnerImg ? (
                  <div className="col s12 m12" >
                    <center><Dots color='#444' /></center>
                  </div>
                ) : null }
              </div>
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (currentCompany ? currentCompany.company_color1 : '')}}>account_circle</i>
              <input placeholder="Nama Lengkap" type="text" className="validate" value={fullName} onChange={e => setFullName(e.target.value)} disabled={data.verified_id_card ? true : false}/>
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (currentCompany ? currentCompany.company_color1 : '')}}>phone</i>
              <input placeholder="Nomor Pribadi" type="text" className="validate" value={phone1} onChange={e => setPhone1(e.target.value)} />
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (currentCompany ? currentCompany.company_color1 : '')}}>email</i>
              <input placeholder="Email" type="text" className="validate" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="col s12 autocomplete-field" style={{paddingLeft: 0, paddingRight: 0}}>
              <label className="m-bottom10">Tentukan Kecamatan Domisili</label>
              <a href='#' className='btn-right-label2' style={{position: 'relative'}} onClick={ onChangeAddress } ><i className="material-icons">edit</i> Ganti Kecamatan</a>
              { editAddress ? (
                <ReactSearchAutocomplete
                  items={districts}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  // formatResult={formatResult}
                  />
              ) : (
                <div className='full-address-box'>
                  <p>{fullAddress.includes('null') ? 'Alamat belum ditentukan' : fullAddress}</p>
                </div>
              )}
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (currentCompany ? currentCompany.company_color1 : '')}}>map</i>
              <input placeholder="Alamat Lengkap" type="text" className="validate" value={address} onChange={e => setAddress(e.target.value)} />
            </div>
            {/* <div className='input-field input-new col s12'>
              <div className='id-card'>
                <div className="form-img-select">
                  <div class='avatar-user'>
                    <ImageUpload
                      handleImageSelect={handleIdCardSelect}
                      imageSrc={idCardSrc}
                      setImageSrc={setIdCardSrc}
                      style={{
                        width: 300,
                        height: 190
                      }}
                    />
                  </div>
                </div>
                <p align='center'>Upload Tanda Pengenal KTP/SIM/Passport</p>
              </div>
            </div>
            <div className="input-field input-new col s12">
              <i className="material-icons span-blue prefix" style={{color: (currentCompany ? currentCompany.company_color1 : '')}}>account_box</i>
              <input placeholder='No Identitas/KTP' type="text" className="validate" value={idCardNumber} onChange={e => setIdCardNumber(e.target.value)} />
            </div> */}
            { spinner ? (
              <div className="box-col">
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (currentCompany.company_color3)}} type="submit" name="action" >
                  <center><Dots color='#fff' /></center>
                </button>
              </div>
            ) : (
              <div className="box-col">
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (currentCompany.company_color3)}} type="submit" name="action" onClick={changeProfile} >
                  Simpan Perubahan
                </button>
              </div>
            ) }
          </div>
        </div>
      )}
    </>
    ) : null
  )
}

export default EditProfile