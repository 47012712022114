import React, { useEffect, useState } from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import BottomSheet from '../components/BottomSheet';
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import API from '../components/utils/api';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useAlert } from 'react-alert';
import Countdown from 'react-countdown';
import { Helmet } from 'react-helmet';

import {
  ShopIcon,
  MoneyIcon,
  PointIcon,
  AffiliateIcon,
  BlogIcon,
  CatalogIcon,
  CustomerIcon,
  InvoiceIcon,
  ReportIcon,
  ToolsIcon,
  ConfirmationIcon,
  ProspectIcon,
  DownloadIcon,
  SettingIcon,
  PresIcon
} from '../assets'
import {
  silverIcon,
  goldIcon,
  platinumIcon,
  dompetIcon
} from '../assets'

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function Dashboard() {
  const alertPlug = useAlert();
  const [company, setCompany] = useState();
  const token = ReactSession.get("token");
  const currentUserLogin = ReactSession.get("current_user");
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState();
  const [paymentNumber, setPaymentNumber] = useState('');
  const [virtualMoney, setVirtualMoney] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [mitra, setMitra] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const [galleriesPromo, setGalleriesPromo] = useState([]);
  const [promo, setPromo] = useState({});
  const [categories, setCategories] = useState([]);
  const [report, setReport] = useState({});
  const [reportDownline, setReportDownline] = useState({});
  const [packages, setPackages] = useState([]);

  const loadData = () => {
    const config1 = { params: {include: 'websetting_galleries'} };
    API.get(`get_company`, config1 )
      .then(res => {
        const data = res.data.data;
        if(data){
          ReactSession.set("current_company", data);
          setCompany(data);
          loadLastPromo(data.id);
          if(data.websetting_galleries){
            setGalleries(data.websetting_galleries.filter((g) => g.category === 'home_app'));
            setGalleriesPromo(data.websetting_galleries.filter((g) => g.category === 'home_promo'));
          }

          loadCategories(data.id);
        }

        var elem = document.querySelector(".slider1");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });

        var elem = document.querySelector(".slider2");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });

        setInterval(() => {
          instance.next();
        }, 2000)
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'websetting_galleries, staff, last_package, downline_count'}};
    API.get(`sessions`, config2, {} )
      .then(res => {
        const data = res.data.data;
        setCurrentUser(data);
        setVirtualMoney(parseInt(data.virtual_money));
        setPaymentNumber(data.payment_bank_name+'-'+data.payment_bank_number);
        if(data.group_user_id > 0){
          setMitra(true)
        }
        getReport(data.id);
      })
      .catch(error => {
        setCurrentUser();
        ReactSession.remove('token')
        ReactSession.remove('current_user')
      })
  }

  const loadCategories = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true, active_promo: true } };
    API.get(`categories`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCategories(data)
      })
  }

  const loadLastPromo = (company_id) => {
    const config_opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_company_id: company_id, pagination: false, by_status: true } };

    API.get(`last_promo`, config_opts)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPromo(data)
      })
  }

  const claimSaldo = () => {
    setSpinner(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const data = {
      category: 'out',
      virtual_money: virtualMoney,
      payment_number: paymentNumber
    }
    
    API.post(`claim_moneys`, data, config )
      .then(res => {
        const data = res.data.data;
        loadData();
        setSpinner(false);
        setOpenModal(false);
        alertPlug.success('Pengajual Berhasil, Tim Kami Akan Memproses Pengajuan Pencairan Dana Anda')
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const getReport = (id) =>{
    const config1 = { headers: { Authorization: `Bearer ${token}`}, params: {by_status_payment: 'paid'} };
    API.get(`report_user`, config1)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_reff_id: id, by_status_payment: 'paid'} };
    API.get(`report_user`, config2)
    .then(res => {
      const data = res.data.report;
      setReportDownline(data)
    })
  }

  const loadPackages = () => {
    const config = { headers: { params: {by_status: true, pagination: false }}};
    API.get(`packages`, config)
      .then(res => {
        const data = res.data.data;
        setPackages(data);
      })
  }

  useEffect(() => {
    if(token === undefined || currentUserLogin === undefined){
      history.push("/")
    }
    loadData();
    loadPackages();
    M.AutoInit();
  },[])

  return (
    company ? (
      <>
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.avatar_url} />
          <link rel="icon" href={company.avatar_url} />
        </Helmet>

        { openModal && currentUser ? (
          <div id="modal1" className='modal modal-visible'>
            <div className='modal-header'>
              Ajukan Pencairan Saldo <i className="material-icons modal-close"  onClick={(e) => setOpenModal(false)}>close</i>
            </div>
            <div className='modal-content'>
              <div className="col s12">
                <div class="profile-info-table space-mb--40 reff" style={{marginBottom:20}}>
                  <div class="profile-info-block" style={{width:'100%', display:'block'}}>
                    <div class="profile-info-block__title">
                      <center>Pengajuan Pencarian Dana Minimal <b>Rp.100.000</b></center>
                    </div>
                  </div>
                </div>
                <label>
                  Masukan Nominal
                </label>
                <div className="input-field input-new">
                  <input className="input-back" value={virtualMoney} onChange={e => setVirtualMoney(e.target.value)} />
                </div>
              </div>
              <div className="col s12 m-bottom0 m-top0 p-top0 select-none-ui">
                <label>Transfer Ke Rekening</label>
                <div className="input-field input-new">
                  <select className='select' value={paymentNumber} onChange={e => setPaymentNumber(e.target.value)} >
                    <option value={currentUser.payment_bank_name+'-'+currentUser.payment_bank_number}>
                      {currentUser.payment_bank_name+'-'+currentUser.payment_bank_number}
                    </option>
                  </select>
                </div>
              </div>
              <div className="col s12">
                { spinner ? (
                  <button className="btn waves-effect waves-light btn-large full-width m-top20" style={{background: (company.company_color3)}} type="submit" name="action" >
                    <center><Dots color='#fff' /></center>
                  </button>
                ) : (
                  <button className="btn waves-effect waves-light btn-large full-width m-top20" style={{background: (company.company_color3)}} type="submit" name="action" onClick={(e) => claimSaldo()} >
                    Ajukan Sekarang
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : null}

        <Header title='Header Loop'/>

        {
          currentUser && currentUser.verified_id_card == false ? (
            <div className='no-verified' style={{position: 'relative', color: 'red'}}>
              Identitas & Nomor Rekening Anda Belum di Verifikasi!<br/> <Link to="/edit_payment">Verifikasi Sekarang</Link>
            </div>
          ) : null
        }

        { currentUser ? (
          <>
            <>
              { galleries && galleries.length > 0 ? (
                <div className="row bg-grey m-bottom0">
                  <div className="slider1 carousel carousel-slider carousel-home carousel-home1">
                    {galleries.map((d, i) =>
                      <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="row bg-grey m-bottom0">
                  <div className="carousel-slider carousel-home blank-carousel carousel-home1" style={{background: (company.company_color2)}}>
                  </div>
                </div>
              ) }
            </>
            <div className="row bg-grey m-bottom30">
              <div className='col col s12 m12 bg-col-acumulation'>
                <div className="wellcome-heading">
                  <div className="row">
                    <div className='col s10 m10'>
                      {
                         company ? (
                          <a href='/'>
                            <img src={company.company_logo_url} className='logo-dashboard'/>
                          </a>
                        ) : null
                      }
                    </div>
                    <div className='col s10 m10' style={{paddingTop:0, paddingBottom:0, marginBottom: 15}}>
                      <h2>
                        {/* {
                          currentUser.last_package && currentUser.last_package.title.includes('Silver') ? (
                            <img src={silverIcon} width='30' className='img-package-card'/>
                          ) : currentUser.last_package && currentUser.last_package.title.includes('Gold') ? (
                            <img src={goldIcon} width='30' className='img-package-card'/>
                          ) : currentUser.last_package && currentUser.last_package.title.includes('Platinum') ? (
                            <img src={platinumIcon} width='30' className='img-package-card'/>
                          ) : null
                        } */}
                        Hi! {currentUser.full_name}
                      </h2>
                      <small style={{position:'absolute', marginTop:-15}}>Selamat datang kembali di {company ? company.full_name : ''}</small>
                    </div>
                    <div className='col s2 m2' style={{paddingRight:0}}>
                      <div style={{background:'none'}} className='acumulation-input acumulation-input-top pointer' onClick={(e) => setOpenModal(true)} >
                        {/* <img src={silverIcon} width='30' className='img-package-card'/> */}
                        <img src={dompetIcon} width='45' style={{}}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='bg-acumulation-container'>
                  <div className="bg-acumulation-home">
                    <div className='col col s6 m6' style={{paddingLeft:0, paddingRight:5}}>
                      <div style={{background:'#4861cd'}} className='bg-acumulation-col acumulation-home pointer' onClick={e => history.replace(`/orders`)}>
                        <div className='acumulation-home-total' style={{textAlign: 'left', color:'white'}}>
                          <b>{report.order_store ? currencyFormat(report.order_store) : numberFormat(0)}</b>
                          <div style={{fontSize: 11}}>Omset Saya</div>
                        </div>
                      </div>
                    </div>
                    <div className='col col s6 m6' style={{paddingLeft:5, paddingRight:0}}>
                      <div style={{background:'#4861cd'}} className='bg-acumulation-col acumulation-home pointer' onClick={e => history.replace(`/downline`)}>
                        <div className='acumulation-home-total' style={{textAlign: 'left', color:'white'}}>
                          <b>{reportDownline.order_store ? currencyFormat(reportDownline.order_store) : numberFormat(0)}</b>
                          <div style={{fontSize: 11}}>Omset Jaringan</div>
                        </div>
                      </div>
                    </div>
                    <div className='col col s6 m6' style={{paddingLeft:0, paddingTop:5, paddingRight:5}}>
                      <div className='bg-acumulation-col pointer' onClick={e => history.replace(`/history_moneys`)}>
                        <div className='acumulation-home-total' style={{textAlign: 'left'}}>
                        <b>{currentUser.virtual_money ? currencyFormat(currentUser.virtual_money) : currencyFormat(0)}</b>
                          <div style={{fontSize: 11}}>Saldo</div>
                        </div>
                      </div>
                    </div>
                    <div className='col col s6 m6' style={{paddingRight:0, paddingTop:5, paddingLeft:5}}>
                      <div className='bg-acumulation-col pointer' onClick={e => history.replace(`/history_points`)}>
                        <div className='acumulation-home-total' style={{textAlign: 'left'}}>
                          <b>{currentUser.virtual_point ? numberFormat(currentUser.virtual_point) : numberFormat(0)}</b>
                          <div style={{fontSize: 11}}>Point</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null }

        {
          currentUser && currentUser.last_package.code == 'basic' ? (
            <div className='no-verified' style={{position: 'relative'}}>
              <div style={{marginBottom:5}}>
                Silahkan Lakukan Pembelian Awal Sesuai Paket yang Diminati : 
              </div>
              <div style={{marginBottom:5}}>
                {
                  packages.map((p, index) => {
                    index+=1
                    return(
                      <>
                        {
                          p.code != 'basic' ? (
                            <li><b>{p.title}</b> Min Pembelian Awal <b>{currencyFormat(p.price)}</b></li>
                          ) : null
                        }
                      </>
                    )
                  })
                }
              </div>
              <Link to="/products">Belanja Sekarang</Link>
            </div>
          ) : null
        }

        {
          currentUser && (currentUser.last_package.code == 'silver' || currentUser.last_package.code == 'gold') ? (
            <div className='no-verified' style={{position: 'relative'}}>
              <div style={{marginBottom:5}}>
                Silahkan Upgrade Paket Anda Untuk Dapat Keuntungan Lebih : 
              </div>
              <div style={{marginBottom:5}}>
                {
                  packages.map((p, index) => {
                    index+=1
                    return(
                      <>
                        {
                          currentUser.last_package.code == 'silver' ? (
                            <>
                              {
                                p.code != 'basic' && p.code != 'silver' ? (
                                  <li><b>Upgrade {p.title}</b> Akumulasi Pembelanjaan <b>{currencyFormat(p.price)}</b></li>
                                ) : null
                              }
                            </>
                          ) : (
                            <>
                              {
                                p.code != 'basic' && p.code != 'silver' && p.code != 'gold' ? (
                                  <li><b>Upgrade {p.title}</b> Akumulasi Pembelanjaan <b>{currencyFormat(p.price)}</b></li>
                                ) : null
                              }
                            </>
                          )
                        }
                      </>
                    )
                  })
                }
              </div>
              <Link to="/products">Upgrade Sekarang</Link>
            </div>
          ) : null
        }

        { galleriesPromo && galleriesPromo.length > 0 ? (
          <div className="row m-bottom0" style={{padding: 10, paddingBottom: 0}}>
            <div className="slider2 carousel carousel-slider carousel-home">
              {galleriesPromo.map((d, i) =>
                <div key={i} className={i === 0 ? `carousel-item active` : `carousel-item`}><img src={d.image_url}/></div>
              )}
            </div>
          </div>
        ) : null }

        <div className='back-white'>
          { mitra && currentUser ? (
            <div className="row m-bottom30">
              <div className="col s12 m12 padding0">
                <div className="icon-apps" onClick={e => history.replace(`/reports`)}>
                  <div className="icon-app">
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>insert_chart</i>
                  </div>
                  <div className='icon-app-name'>Report</div>
                </div>
                <div className="icon-apps" onClick={e => history.replace(`/payments`)}>
                  <div className="icon-app">
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>exit_to_app</i>
                  </div>
                  <div className='icon-app-name'>Pembayaran</div>
                </div>
                <div className="icon-apps" onClick={e => history.replace(`/blogs`)}>
                  <div className="icon-app">
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>ondemand_video</i>
                  </div>
                  <div className='icon-app-name'>
                    Blog
                  </div>
                </div>
                <div className="icon-apps" onClick={e => history.replace(`/content_kit`)}>
                  <div className="icon-app">
                    <i class="material-icons dp48" style={{color: (company.company_color1)}}>photo_camera</i>
                  </div>
                  <div className='icon-app-name'>Konten Kit</div>
                </div>
              </div>
            </div>
          ) : null }

          { company && promo && promo.id ? (
            <ProductSection token={token} title={promo.title} currentUser={currentUser} currentCompany={company} promo={promo} promo_id={promo.id}/>
          ) : null}

          { categories && categories.length > 4 ? (
            <div className="row p-top-10 back-grey" style={{marginBottom: 0}}>
              <div className="col s12 m12" style={{padding: 0}}>
                <div className='scroll-touch'>
                  <div className='scroll-container'>

                    {
                      categories.map((p) => {
                        return (
                          <Link to={'/products?category='+p.id} className="icon-categories">
                            <div className="icon-image">
                              <img src={p.image_url}/>
                            </div>
                            <div className='icon-category-name'>{p.name}</div>
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          { company && !promo ? (
            <ProductSection token={token} title='Promo Sekarang' currentUser={currentUser} currentCompany={company}/>
          ) : (
            <ProductSection token={token} title='Terbaru' currentUser={currentUser} currentCompany={company}/>
          ) }

          <ProductSection token={token} title='Terlaris' currentUser={currentUser} currentCompany={company}/>
        </div>
        <BottomSheet/>
      </>
    ) : null
  );
}

export default Dashboard;
