import React, { useEffect, useState } from 'react';

const Home = () => {
  return (
    <div>
      <span>Home</span>
    </div>
  )
}

export default Home