import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Carts = () => {
  const currentCompany = ReactSession.get("current_company");
  const {id} = useParams();
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [company, setCompany] = useState({});
  const [paymentNumbers, setPaymentNumbers] = useState([]);
  const [spinnerScreen, setSpinnerScreen] = useState(true);

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'order_items, product, order_item_codes'} };
    API.get(`orders/${id}`, config)
      .then(res => {
        setSpinnerScreen(false);
        const data = res.data.data;
        setOrder(data);
        setOrderItems(data.order_items);

        const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'payment_accounts'} };
        API.get(`user_company`, config2)
          .then(res => {
            const com = res.data.data;
            setCompany(com);
            setPaymentNumbers(com.payment_accounts);
          })
      })
    
  }

  const cancelOrder = () => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const fd = new FormData();
    fd.append('status_payment', 'cancelled');
    fd.append('status_shipping', 'cancelled');
    fd.append('include', 'order_items, product, order_item_codes');
    API.put(`orders/${id}`, fd, config )
      .then(res => {
        loadData();
      })
  }

  const receiveOrder = () => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const fd = new FormData();
    fd.append('status_shipping', 'received');
    fd.append('include', 'order_items, product, order_item_codes');
    API.put(`orders/${id}`, fd, config )
      .then(res => {
        loadData();
      })
  }

  useEffect(() => {
    if(token === undefined || currentUser === undefined){
      history.push("/login")
    }

    loadData();
  
  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/orders" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Detail Pesanan {order.code ? order.code.toUpperCase() : null}</span>
          </div>
        </nav>
      </div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div className='sidenav-overlay'></div>
          <div className="row m-bottom0 back-white">
            <div className="">
              <div className="card horizontal card-cart">
                <b>Kode Pesanan</b>
                <label className='btn-right-label2'>
                  {order.code ? order.code.toUpperCase() : null}
                </label>
              </div>

              <div className="row border-bottom">
                <div className="col s12 m12">
                  <div className='sub-order-total'> 
                    Total Pembayaran 
                    <br/>
                    <span className='total'>
                      {currencyFormat((parseInt(order.store_price)+(parseInt(order.shipping_price)+parseInt(order.fee_cod)+parseInt(order.uniq_code)) - parseInt(order.subsidi)) - parseInt(order.virtual_money))}
                    </span>
                    <br/>
                    <span className='status-payment' style={styles.status_payment(order.status_payment)}>{order.status_payment ? order.status_payment_txt.toUpperCase() : null}</span>
                    <br/>

                    {
                       paymentNumbers && order.payment_method === 'Transfer' ? (
                        <small>Batas Waktu Pembayaran {order.due_date}</small>
                       ) : null
                    }
                    <br/>

                    { order.status_payment == 'pending' ? (
                      <>
                        <br/>
                        <button onClick={cancelOrder} className='btn btn-cancel' style={{background: 'red'}}>Batalkan Pesanan</button>
                      </>
                    ) : null}

                    { order.status_payment == 'paid' && order.status_shipping === 'shipped' ? (
                      <>
                        <br/>
                        <button onClick={receiveOrder} className='btn btn-accept' style={{background: 'green'}}>Pesanan Diterima</button>
                      </>
                    ) : null}
                    
                  </div>
                </div>
              </div>

              { paymentNumbers && order.payment_method === 'Transfer' && order.status_payment != 'paid' ? (
                <div className="row border-bottom">
                  <div className="col s12 m12">
                    <div className='box-bank-number'> 
                      <small>Silahkan Untuk Pembayaran Via Transfer Bisa Ke Norek Dibawah Ini, Lalu Konfirmasi Pembayaran Anda Kepada Customer Service Kami :</small>
                      <br/><br/>
                      {
                        paymentNumbers.map((p) => {
                          return(
                            <div className='list-bank-number'>
                              <b>{p.name} {p.code}</b>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              ) : order.status_payment === 'paid' ? null : (
                <div className="row border-bottom">
                  <div className="col s12 m12">
                    <div className='box-bank-number'> 
                      <small>Silahkan Sediakan Pembayaran Belanja Anda <br/>Ketika Barang Sudah Sampai Dilokasi Pengiriman Yang Dituju.</small>
                    </div>
                  </div>
                </div>
              ) }

              <div className="card horizontal card-cart">
                <b>Data Pengirim</b>
              </div>

              <div className="card horizontal card-cart">
                <div className="row m-bottom0">
                  <div className='full-address-box'>
                    <p>
                      <div>
                        {order.store_name} ({order.store_number})
                      </div>
                      <div>
                        {order.store_address}
                      </div>
                    </p>
                  </div>
                </div>
              </div>

              <div className="card horizontal card-cart">
                <b>Status Pengiriman</b>
                <label className='btn-right-label2' style={styles.status(order.status_shipping)}>
                  {order.status_shipping ? order.status_shipping_txt.toUpperCase() : null}
                </label>
              </div>

              <div className="card horizontal card-cart">
                <div className="row m-bottom0">
                  <div className='full-address-box'>
                    <p>
                      <div>
                        {order.customer_name} ({order.customer_phone})
                      </div>
                      <div>
                        {order.customer_email}
                      </div>
                      <div>
                        {order.customer_address}, {order.customer_district}, {order.customer_city}, {order.customer_province}
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="card horizontal card-cart">
                <b>Detail Pemesanan</b>
                <label className='btn-right-label2'>
                  {order.order_date}
                </label>
              </div>

              { orderItems ? (
                orderItems.map((oi) => {
                  return (
                    <div key={oi.id} className="card horizontal card-cart">
                      <div className="card-image">
                        <img src={oi.product.image_url}/>
                      </div>
                      <div className="card-stacked">
                        <div className="card-content padding-top0">
                          <div className='col col s8 m8 padding0'>
                            <div className='card-product'>
                              <Link to={'product/'+oi.product_id} >{oi.product_name}</Link>
                            </div>
                            { oi.variant_name_1 ? (
                              <div className='card-variant'>
                                <label className='label label-default'>Varian {oi.variant_name_1} {oi.variant_name_2}</label>
                              </div>
                            ) : null}
                            <div>
                              { 
                                oi.order_item_codes.map((oc) => {
                                  return (
                                    <label className='label label-default' style={{marginRight: 2, marginTop: 5, fontSize: 8}}>
                                      {oc.code}
                                    </label>
                                  )
                                })
                              }
                            </div>
                          </div>
                          
                          <div className='col col s4 m4 padding0'>
                            <div className='card-variant text-right'>
                              <span className=''>
                                {oi.quantity} x
                                <br/>
                                { oi.discount > 0 ? (
                                  <>
                                    <span className='discount-price'>{currencyFormat(parseInt(oi.store_price)+(parseInt(oi.discount)/parseInt(oi.quantity)))}</span>
                                    <br/>
                                  </>
                                ) : null }
                                {currencyFormat(oi.store_price)}
                              </span>
                            </div>
                            { oi.member_point > 0 ? (
                              <div className='card-variant text-right'>
                                <label className='label label-point'>{oi.member_point} POIN</label>
                              </div>
                            ) : null }
                            { oi.member_cashback > 0 ? (
                              <div className='card-variant text-right'>
                                <label className='label label-cashback'>{currencyFormat(oi.member_cashback)} Cashback</label>
                              </div>
                            ) : null }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : null }

              <div className="card horizontal card-cart">
                <div className='row m-bottom0'>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                    <b>Berat</b>
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                    <span>{order.expedition_weight} gr</span>
                  </div>
                </div>
              </div>

              { order.expedition_number && order.expedition_number.length > 4 ? (
                <div className="card horizontal card-cart">
                  <div className='row m-bottom0'>
                    <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                      <b>Nomor Resi</b>
                    </div>
                    <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                      <span>{order.expedition_number}</span>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="card horizontal card-cart">
                <div className='row m-bottom0'>
                  <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0">
                    <b>Expedisi {order.expedition_name ? order.expedition_name.toUpperCase() : null} {order.expedition_service}</b>
                  </div>
                  <div className="input-field col s6 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 text-right">
                    <b>{currencyFormat(order.shipping_price)}</b>
                  </div>  
                </div>
              </div>
              
              <div className="card horizontal card-cart">
                <div className='row m-bottom0'>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                    <b>Transaksi Anda</b>
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                    <b>Rincian Harga</b>
                  </div>
                </div>
              </div>
              <div className="card horizontal card-cart">
                <div className='row m-bottom0'>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    Subtotal Produk Anda
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    {currencyFormat(order.store_price)}
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    Subtotal Ongkos Kirim
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    {currencyFormat( (parseInt(order.shipping_price)) )}
                  </div>
                  {
                    order.uniq_code > 0 ? (
                      <>
                        <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                          Biaya Penanganan
                        </div>
                        <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                          {currencyFormat((parseInt(order.uniq_code)))}
                        </div>
                      </>
                    ) : null
                  }
                  { order.customer_payment_method === 'COD' ? (
                    <>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        Biaya Penanganan COD
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        {currencyFormat( (parseInt(order.fee_cod)) )}
                      </div>
                    </>
                  ) : null}

                  { order.subsidi > 0 ? (
                    <>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        Potongan Subsidi Ongkir
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        - {currencyFormat(order.subsidi)}
                      </div>
                    </>
                  ) : null }
                  
                  { parseInt(order.virtual_money) > 0 ? (
                    <>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        BAYAR PAKAI SALDO
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        - {currencyFormat(order.virtual_money)}
                      </div>
                    </>
                  ) : null
                  }
                  
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                    <b>TOTAL PEMBAYARAN ANDA</b>
                  </div>
                  <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                    <b>{currencyFormat((parseInt(order.store_price)+(parseInt(order.shipping_price)+parseInt(order.fee_cod)+parseInt(order.uniq_code)) - parseInt(order.subsidi)) - parseInt(order.virtual_money))}</b>
                  </div>
                </div>
              </div>

              { order.dropship === true ? (
                <>
                  <div className="card horizontal card-cart">
                    <div className='row m-bottom0'>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0">
                        <b>Detail Tagihan Ke Konsumen</b>
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                        <b>Rincian Harga</b>
                      </div>
                    </div>
                  </div>
                  <div className="card horizontal card-cart">
                    <div className='row m-bottom0'>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        Subtotal Produk Konsumen
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        {currencyFormat(order.sell_price)}
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        Subtotal Ongkos Kirim
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        {currencyFormat((parseInt(order.shipping_price)))}
                      </div>
                      { order.dropship === true && order.customer_payment_method === 'COD' ? (
                        <>
                          <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                            Biaya Penanganan COD
                          </div>
                          <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                            {currencyFormat( (parseInt(order.fee_cod)) )}
                          </div>
                        </>
                      ) : null}
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0">
                        <b>TOTAL {order.customer_payment_method.toUpperCase()}</b>
                      </div>
                      <div className="input-field col s6 m-top0 p-top0 p-bottom0 text-right">
                        <b>{currencyFormat(parseInt(order.sell_price)+(parseInt(order.shipping_price)+parseInt(order.fee_cod)))}</b>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="row border-bottom add-chart-seaction">
                <div className="col s12 m12">
                  <a href={'https://api.whatsapp.com/send?phone='+company.phone1+'&text=Hallo%20admin,%20Saya%20mau%20konfirmasi%20pembayaran%20lewat%20aplikasi%20'+currentCompany.full_name+'%20nomor%20pesanan%20'+order.code} className='btn btn-confirm' style={{background: (currentCompany.company_color3)}}>Konfirmasi Pembayaran</a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
    ) : null
  );
}

export default Carts;

const styles: StyleSheet = {
  status: (status) => ({
    color: status === 'pending' ? 'orange' : (status === 'shipped' || status === 'received') ? 'green' : status === 'packing' ? '#11cdef' : 'red' ,
  }),
  status_payment: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}
