import React, { useState, useEffect } from 'react'
import API from './utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import Countdown from 'react-countdown';
import ReactSession from '../ReactSession';
import LinesEllipsis from 'react-lines-ellipsis';

function currencyFormat(num) {
  return 'Rp.' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ProductSection = ({token, title, currentUser, promo, promo_id, currentCompany}) => {
  const [products, setProducts] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const loadData = () => {
    let config = {}
    if(title === 'Promo Sekarang'){
      config = { headers: { Authorization: `Bearer ${token}`}, params: {per_page: 10, by_position: 'updatest', by_promo: true, include: 'product_prices, product_variants', by_status: true} };
    }else if(title === 'Terlaris'){
      config = { headers: { Authorization: `Bearer ${token}`}, params: {per_page: 30, by_position: 'updatest', by_bestseller: true, include: 'product_prices, product_variants', by_status: true} };
    }else if(promo_id){
      config = { headers: { Authorization: `Bearer ${token}`}, params: {per_page: 30, by_position: 'updatest', by_promo_id: promo_id, include: 'product_prices, product_variants', by_status: true} };
    }else{
      config = { headers: { Authorization: `Bearer ${token}`}, params: {per_page: 30, by_position: 'latest', by_promo_id: promo_id, include: 'product_prices, product_variants', by_status: true} };
    }

    API.get(`products`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setProducts(data)
        setSpinner(false)
      })
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="row p-top-10 margin-none">

      { products && products.length > 0 ? (
        <>
          <div className="col s8 m8">
            <span className='title-card-content'>{title}</span>
          </div>
          <div className="col s4 m4 text-right">
            { promo && promo.end_date ? (
              <div className='countdown-txt'>
                <Countdown date={promo.end_date}/>
              </div>
            ) : (
              <Link className='title-view-more' to="/products" className="more-opts">Lihat Semua</Link>
            )}
          </div>
        </>
      ) : null }
      
      <div className="col s12 m12" style={{paddingTop: 0}} >
        <div className='scroll-touch'>
          <div className='scroll-container'>
            { spinner ? (
                <div className="col s12 m12" >
                  <center><Dots color='#444' /></center>
                </div>
              ) : (
                products.map((p) => {
                    let price = p.sell_price;
                    let discount = 0;
                    if(p.product_type == 'price_on_variant' && p.product_variants && p.product_variants.filter(o => o.parent_id === null).length > 0){
                      let varian_sku = p.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id)[0]
                      if(p.product_prices.length > 0){
                        let pPrice = p.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser ? currentUser.group_user_id : 0) && o.product_variant_id === varian_sku.id )[0]
                        if(pPrice !== undefined){
                          price = pPrice.sell_price;
                        }else{
                          price = varian_sku.sell_price;
                        }
                      }
                    }else{
                      let pPrice = p.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser ? currentUser.group_user_id : 0) && o.product_variant_id === null)[0]
                      if(pPrice !== undefined){
                        price = pPrice.sell_price;
                      }
                    }

                    if(currentUser === undefined || (currentUser && (currentUser.group_user_id === null || currentUser.group_user_id === 0))){
                      discount = parseInt(p.discount_customer)
                    }else{
                      discount = parseInt(p.discount)
                    }

                    return (
                      <div className="col-product" key={p.id}>
                        <Link to={'/product/'+p.id} className="card card-product vertical" style={{background: 'white'}}>
                          <div className="card-image" style={{height: 160}}>
                            {discount > 0 ? (
                              <div className='discount-label'>
                                <div>{parseInt(discount)}%</div>
                                <div className='off-disc'>OFF</div>
                              </div>
                            ) : null}
                            {/* {p.point > 0 ? (<div className='point-label'><div>{parseInt(p.point)}</div><div className='off-disc'>POIN</div></div>) : null} */}
                            {p.stock < 1 ? (<div className='outstock-label'>STOK HABIS</div>) : null}
                            <img src={p.image_url}/>
                          </div>
                          <div className="card-content">
                            <div className='title-card'>
                              <LinesEllipsis
                                text={p.title}
                                maxLine='2'
                                ellipsis='...'
                                trimRight
                                basedOn='letters'
                              />    
                            </div>
                            <div className='category-card'>{p.category_name}</div>
                            <div className='price-card' style={{color: (currentCompany ? currentCompany.company_color2 : '')}}>
                              {discount > 0 ? (<span className='discount-price'>{numberFormat(price)}</span>) : null} {numberFormat(parseInt(price)-(parseInt(price)*parseInt(discount)/100))}
                            </div>
                            <div style={{position: 'relative', marginTop: 5}}>
                              {p.subsidi > 0 ? (<div className='free-shipping'>FREE ONGKIR</div>) : null}
                              {p.cashback > 0 ? (<div className='cashback' style={{ left: p.subsidi < 1 ? 0 : 75}}>CASHBACK</div>) : null}
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  }
                )
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSection
