import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import BottomSheet from '../components/BottomSheet';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ContentKit = () => {
  const currentUser = ReactSession.get("current_user");
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();

  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerScreen, setSpinnerScreen] = useState(true);

  const loadData = (keyword) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'company'  }};
    API.get(`brands`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', include: 'company' } };
    
    API.get(`brands`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    loadData(search);
  }

  useEffect(() => {  
    if(token === undefined || currentUser === undefined){
      history.push("/login")
    }

    loadData();
    setSpinnerScreen(false);  

  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Konten Marketing Kit</span>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div class='row m-bottom0'>
            <div className="col s12">
              <div className="input-field search-on-list m-top0">
                <input className="input-back" autocomplete="off" type="search" value={search} onChange={e => updateSearch(e.target.value)}/>
                <label className="label-icon" for="search"><i className="material-icons material-icon-search" style={{color: (currentCompany.company_color1)}}>search</i></label>
              </div>
              <>
                { spinner ? (
                  <div className='text-center'>
                    <div className='loadLoading'><Dots color='#444' /></div>
                  </div>
                ) : (
                  <>
                    { data && data.length > 0 ? (
                      <>
                        {
                          data.map((u) => {
                            return (
                              <div className='list-content' key={u.id}>
                                <div className="avatar rounded-circle mr-3" href="#" onClick={(e) => e.preventDefault()}>
                                  <img alt="..." src={u.image_url} />
                                </div>
                                <div className='list-content-text'>
                                  <b>MARKETING KIT {u.name.toUpperCase()}</b>
                                  <br/>
                                  {u.link_content ? (
                                    <>
                                      <a href={u.link_content} className="btn btn-default btn-download" target='_blank'>Download Sekarang</a>
                                    </>
                                  ) : 'Belum Ada'}
                                </div>
                              </div>
                            )
                          })
                        }
                      </>
                      ) : (
                        <div className='blank-message'>
                          Data Tidak Ditemukan
                        </div>
                      )
                    }
                  </>
                )}
                {paging ? (
                  <div onClick={loadMoreData} class='load-more-btn-white'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                ) : null}
              </>
            </div>
          </div>

        </>
      )}
      <BottomSheet/>
    </>
    ) : null
  );
}

export default ContentKit;
