import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css'
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function createMarkup(desc) {
  return {__html: desc.split('\n').map(str => <p>{str}</p>)};
}

function NewlineText(props) {
  const text = props.text;
  return text.split('\n').map(str => <p>{str}</p>);
}

const ProductShow = () => {
  const {id} = useParams();

  const currentCompany = ReactSession.get("current_company");
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const current_order_id = ReactSession.get("current_order_id");
  const current_order_count = ReactSession.get("current_order_count");
  const [showBoxCart, setShowBoxCart] = useState(false);
  const [readyOrder, setReadyOrder] = useState(false);
  const [favorited, setFavorited] = useState(false);

  const [company, setCompany] = useState({});
  const [product, setProduct] = useState({});
  const [productPrices, setProductPrices] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [variant1, setVariant1] = useState('');
  const [variant2, setVariant2] = useState('');
  const [variant1Obj, setVariant1Obj] = useState({});
  const [variant2Obj, setVariant2Obj] = useState({});
  const [productPrice, setProductPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);

  const [variants2, setVariants2] = useState([]);
  const [haveVariant2, setHaveVariant2] = useState(false);
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [errorMessages, setErrorMessages] = useState();

  const IncrementItem = () => {
    setQuantity(quantity+1);
  }

  const DecreaseItem = () => {
    let qty = quantity-1
    if(qty <= 1){
      setQuantity(1);
    }else{
      setQuantity(quantity-1);
    }
  }

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'user, merchant, product_variants, product_variant_prices, product_prices, product_galleries'} };

    API.get(`products/${id}`, config)
      .then(res => {
        setSpinnerScreen(false);
        const data = res.data.data;
        setProduct(data);
        setQuantity(1);
        setProductPrices(data.product_prices);
        setProductPrice(data.sell_price);
        // setSellPrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(data.discount_customer)/100));
        setSellPrice(parseInt(data.sell_price));
        if(currentUser === undefined || (currentUser && (currentUser.group_user_id === null || currentUser.group_user_id === 0))){
          setDiscount(parseInt(data.discount_customer))
        }else{
          setDiscount(parseInt(data.discount))
        }
        if(data.product_variants && data.product_variants.filter(o => o.parent_id === null).length > 0){
          let varian_sku = data.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id)[0]
          let opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_sku: varian_sku.sku} }
          setVariant1(varian_sku.sku);
          setVariant1Obj(varian_sku);

          if(data.product_type == 'price_on_variant' && data.product_prices.length > 0){
            let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser ? currentUser.group_user_id : 0) && o.product_variant_id === varian_sku.id )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
            }else{
              setProductPrice(varian_sku.sell_price);
            }
            // setSellPrice(parseInt(varian_sku.sell_price)-(parseInt(varian_sku.sell_price)*parseInt(data.discount_customer)/100));
            setSellPrice(parseInt(varian_sku.sell_price));
          }else{
            let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser ? currentUser.group_user_id : 0) && o.product_variant_id === null )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
            }
          }

          API.get(`product_variants`, opts)
            .then(res => {
              const data = res.data.data;
              if(data && data.length > 0){
                setVariant2(data[0].sku);
                setVariant2Obj(data[0])
                setHaveVariant2(true)
                setVariants2(data);
              }else{
                setVariant2('');
                setVariant2Obj({})
                setHaveVariant2(false)
                setVariants2([]);
              }
            })
        }else{
          let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser ? currentUser.group_user_id : 0) && o.product_variant_id === null )[0]
          if(pPrice !== undefined){
            setProductPrice(pPrice.sell_price);
          }
        }

        M.AutoInit();
        var elem = document.querySelector(".carousel");
        var instance = window.M.Carousel.init(elem, {
          fullWidth: true,
          indicators: true,
        });
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {include: ''} };
    API.get(`user_company`, config2)
      .then(res => {
        const com = res.data.data;
        setCompany(com);
      })
  }

  const loadVariant = (sku) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {sku: sku} };
    setVariant1(sku);
    API.get(`product_variant_sku`, config)
      .then(res => {
        const data = res.data.data;
        setVariant1Obj(data);
        let pPrice = product.sell_price;

        if(product.product_type == 'price_on_variant'){
          pPrice = productPrices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === data.id )[0]
          if(pPrice !== undefined){
            setProductPrice(pPrice.sell_price);
          }else{
            setProductPrice(data.sell_price);
          }
          // setSellPrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(product.discount_customer)/100));
          setSellPrice(parseInt(data.sell_price));
        }else{
          pPrice = productPrices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id) && o.product_variant_id === null )[0]
          if(pPrice !== undefined){
            setProductPrice(pPrice.sell_price);
          }
        }
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_sku: sku} };
    API.get(`product_variants`, config2)
      .then(res => {
        const data = res.data.data;
        if(data && data.length > 0){
          setVariant2(data[0].sku);
          setVariant2Obj(data[0])
          setHaveVariant2(true)
          setVariants2(data);
        }else{
          setVariant2('');
          setVariant2Obj({})
          setHaveVariant2(false)
          setVariants2([]);
        }
      })
  }

  const setLoadVariant2 = (sku) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {sku: sku} };
    setVariant2(sku);

    API.get(`product_variant_sku`, config)
      .then(res => {
        const data = res.data.data;
        setVariant2Obj(data)
      })
  }

  const addCart = () => {
    setSpinner(true);
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const paramsData = { 
      product_id: id, drop_price: sellPrice, variant_sku_1: variant1, variant_sku_2: variant2, quantity: quantity, order_id: current_order_id
    };

    API.post('order_items', paramsData, config)
      .then(res => {
        setSpinner(false);
        alert.success('Produk ini berhasil di masukan ke keranjang anda')
        const data = res.data.data;
        ReactSession.set("current_order_id", data.order_id);
        let coc = current_order_count ? current_order_count : 0
        ReactSession.set("current_order_count", coc+1);
        setShowBoxCart(false);
        setReadyOrder(true);
        // let elSide = document.querySelectorAll('.sidenav-overlay');
        // let elBody = document.querySelectorAll('body');
        // elSide.forEach((item: any) => {
        //   item.style.display = 'none';
        // })
        // elBody.forEach((item: any) => {
        //   item.style.overflow = 'auto';
        // })
      }).catch(error => {
        setSpinner(false);
        if(error === undefined){
          setErrorMessages('Gagal, Telah terjadi kesalahan server');
        }else{
          setErrorMessages(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const onShowCart = () => {
    setShowBoxCart(true);
    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'block';
    //   item.style.opacity = '1';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'hidden';
    // })
  }

  const onHideCart = () => {
    setShowBoxCart(false);
    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'none';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'auto';
    // })
  }

  const changeFavorited = (id) => {
    if(token === undefined || currentUser === undefined){
      history.push("/login")
    }else{
      const config = {
        headers: { Authorization: `Bearer ${token}` } 
      };

      API.post(`whistlists`, {product_id: id}, config )
        .then(res => {
          const data = res.data.data;
          if(data){
            setFavorited(true)
          }else{
            setFavorited(false)
          }
        })
        .catch(error => {
          console.log(error.response);
        })
    }
  }

  const checkFavorited = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    API.get(`check_whistlist/${id}`, config )
      .then(res => {
        const data = res.data.data;
        if(data){
          setFavorited(true)
        }else{
          setFavorited(false)
        }
      })
      .catch(error => {
        setFavorited(false)
        console.log(error.response);
      })
  }

  
  useEffect(() => {
    if(token === undefined || currentUser === undefined){
      history.push("/")
    }

    loadData();
    checkFavorited(id);

    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'none';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'auto';
    // })

  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/products" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Detail Produk</span>
            <ul className="right">
              <li>
                <Link to="/carts">
                  <i className="material-icons span-blue" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>shopping_basket</i>
                  { current_order_count ? ( <span className='icon-shop-count'>{current_order_count}</span> ) : null }
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div className="row m-bottom-0 back-grey">
            { product && product.product_galleries && product.product_galleries.length > 0 ? (
              <div className="carousel carousel-slider carousel-product">
                {product.stock < 1 ? (<div className='outstock-label'>STOK HABIS</div>) : null}
                {product.subsidi > 0 ? (<div className='free-shipping' style={{width: 110, fontSize: 12, bottom: 0}}>GRATIS ONGKIR</div>) : null}
                {product.cashback > 0 ? (<div className='cashback' style={{bottom: 0, left: 110, fontSize: 12, background: (currentCompany ? currentCompany.company_color4 : '')}}>CASHBACK</div>) : null}
                {product.point > 0 ? (<div className='point-label' style={{top: 10, right: 10, left: 'auto'}}>{parseInt(product.point)}<br/><span className='off-disc'>POIN</span></div>) : null}
                <div className="carousel-item active"><img src={product.image_url}/></div>
                {product.product_galleries.map((d, i) => 
                  <div key={i} className="carousel-item"><img src={d.image_url}/></div>
                )}
              </div>
            ) : (
              <div className="carousel carousel-slider carousel-product">
                {product.stock < 1 ? (<div className='outstock-label'>STOK HABIS</div>) : null}
                {product.subsidi > 0 ? (<div className='free-shipping' style={{width: 110, fontSize: 12, bottom: 0}}>GRATIS ONGKIR</div>) : null}
                {product.cashback > 0 ? (<div className='cashback' style={{bottom: 0, left: 110, fontSize: 12, background: (currentCompany ? currentCompany.company_color4 : ''), left: product.subsidi < 1 ? 0 : 110}}>CASHBACK</div>) : null}
                {product.point > 0 ? (<div className='point-label' style={{top: 10, right: 10, left: 'auto'}}>{parseInt(product.point)}<br/><span className='off-disc'>POIN</span></div>) : null}
                <div className="carousel-item active"><img src={product.image_url}/></div>
              </div>
            ) }
          </div>

          <div className="row border-bottom back-white">
            <div className="col s11 m11 p-bottom-0">
              <div>
                <small>{product.category_name ? product.category_name : ''} {product.sub_category_name ? product.sub_category_name : ''} {product.brand_name ? product.brand_name : ''}</small>
              </div>
              <div className='title-card-show'>{product.title}</div>
            </div>

            <div className='col s1'>
              <div className='love-whistlist'>
                { favorited ? (
                  <i className="material-icons" onClick={(e) => changeFavorited(product.id)}>favorite</i>
                ) : (
                  <i className="material-icons" onClick={(e) => changeFavorited(product.id)}>favorite_border</i>
                )}
              </div>
            </div>

            <div className='col s10'>
              <div className='title-card-price' style={{color: (currentCompany ? currentCompany.company_color2 : '')}}>
                {discount > 0 ? (<span className='discount-price' style={{fontSize: 16}}>{currencyFormat(productPrice)}</span>) : null} {currencyFormat(parseInt(productPrice)-(parseInt(productPrice)*parseInt(discount)/100))}
              </div>
            </div>

            <div className='col s2'>
              {discount > 0 ? (<div className='discount-label-big' style={{fontSize: 16}}>{parseInt(discount)}% <span className='off-disc' style={{fontSize: 15}}></span></div>) : null}
            </div>
          </div>

          <div className="row border-bottom back-white">
            <div className="col s12 m12">
              <div className='title-card-desc'>
                { product && product.description ? (
                  <NewlineText text={product.description} />
                ) : null}
              </div>
            </div>
          </div>

          <div className="row border-bottom back-white">
            { product && product.merchant && product.merchant.id ? (
              <>
                <div className="col s12 m12">
                  <div className='title-card-val'>{product.merchant.district}, {product.merchant.city}, {product.merchant.province}</div>
                </div>
              </>
            ) : product && product.user ? (
              <>
                <div className="col s12 m12">
                  <div className='title-card-val'>
                    <i class="material-icons left">location_on</i> Lokasi : {product.user.district}, {product.user.city}, {product.user.province}
                  </div>
                </div>
              </>
            ) : null}
          </div>

          {
            product.product_variants && product.product_variants.length > 0 ? (
              <>
                <div className="row border-bottom back-white">
                  <div className="col s12 m12">
                    <b>Ketersediaan Varian, Stok dan Harga</b>
                  </div>
                </div>

                <div className="row border-bottom back-white">
                  <div className="col s12 m12">
                    <table>
                      <thead>
                        <tr>
                          <td width='25%' className='title-card-label'>Varian</td>
                          <td width='25%' className='title-card-label'>Stok</td>
                          { product.product_type === 'price_on_variant' ? (
                            <>
                              {
                                currentUser && currentUser.group_user_id > 0 ? (
                                  <td width='25%' className='title-card-label'>Harga Mitra</td>
                                ) : null
                              }
                              <td width='25%' className='title-card-label'>Harga Konsumen</td>
                            </>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          product.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id).map((p) => {
                            return (
                              <tr key={p.id}>
                                <td className='title-card-val'>{p.variant_name}</td>
                                <td className='title-card-val'>{p.stock}</td>
                                { product.product_type === 'price_on_variant' ? (
                                  <>
                                    {
                                      currentUser && currentUser.group_user_id > 0 ? (
                                        <td className='title-card-val'>
                                          {
                                            p.product_prices && currentUser && currentUser.group_user_id ? (
                                              p.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(currentUser.group_user_id)).map((p) => {
                                                return (
                                                  <span>{currencyFormat(p.sell_price)}</span>
                                                )
                                              })
                                            ) : null
                                          }
                                        </td>
                                      ) : null
                                    }
                                    <td className='title-card-val'>{currencyFormat(p.sell_price)}</td>
                                  </>
                                ) : null}
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            ) : null
          }

          { showBoxCart ? (
            <div className="row border-bottom widget-box-chart">
              <div className="modal-widget-box-chart">
                <div className='closeBtn' onClick={onHideCart}> X</div>
                <div className="col s12 m12">
                  <div className="card horizontal">
                    <div className="card-image">
                      <img src={product.image_url}/>
                    </div>
                    <div className="card-stacked">
                      <div className="card-content padding-top0">
                        <p>{product.title}</p>
                        { variant1Obj.variant_name ? (
                          <div className='card-variant'>{variant1Obj.variant_name} {variant2Obj.variant_name}</div>
                        ) : null}
                        <b className='card-variant'>
                          {discount > 0 ? (<span className='discount-price'>{currencyFormat(productPrice)}</span>) : null} {currencyFormat(parseInt(productPrice)-(parseInt(productPrice)*parseInt(discount)/100))}
                        </b>
                      </div>
                    </div>
                  </div>

                </div>

                {
                  product.product_variants && product.product_variants.length > 0 ? (
                    <>
                      <div className="col s5 m5">
                        <div className='title-card-label m-top-10'>Pilih Varian</div>
                      </div>
                      <div className="col s7 m7 select-none-ui">
                        <select className="sel" value={variant1} onChange={e => (loadVariant(e.target.value))} >
                          {
                            product.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id).map((p) => {
                              return (
                                <option key={p.id} value={p.sku}>{p.variant_name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </>
                  ) : null
                }

                {
                  haveVariant2 && variants2 ? (
                    <>
                      <div className="col s5 m5">
                        <div className='title-card-label m-top-10'>Pilih Varian 2</div>
                      </div>
                      <div className="col s7 m7 select-none-ui">
                        <select className="sel" value={variant2} onChange={e => (setLoadVariant2(e.target.value))} >
                          {
                            variants2.map((p) => {
                              return (
                                <option key={p.id} value={p.sku}>{p.variant_name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </>
                  ) : null
                }

                <div className="col s5 m5">
                  <div className='title-card-label m-top-10'>Jumlah</div>
                </div>
                <div className="col s7 m7">
                  <div className='input-group'>
                    <button className='btn btn-default no-radius' onClick={IncrementItem}>+</button>
                    <input placeholder="Jumlah" type="number" min='1' className="validate input-border" value={quantity} onChange={e => setQuantity(e.target.value)} />
                    <button className='btn btn-default no-radius' onClick={DecreaseItem}>-</button>
                  </div>
                </div>

                { currentUser.group_user_id > 0 ? (
                  <>
                    <div className="col s5 m5">
                      <div className='title-card-label m-top-10'>
                        Harga Konsumen<br/>
                        <small>( Tentukan Jika Dropship )</small>
                      </div>
                    </div>
                    <div className="col s7 m7">
                      <input placeholder="Jumlah" type="number" min={sellPrice} className="validate input-border input-cell" value={sellPrice} onChange={e => setSellPrice(e.target.value)} />
                    </div>
                  </>
                ) : null}

                <div className="col s12 m12">
                  { errorMessages ? (
                    <div className="error-card">
                      <span>{errorMessages}</span>
                    </div>
                  ) : null}
                  { spinner ? (
                    <button className='btn btn-chart onBtnCart' style={{background: (currentCompany.company_color3)}}><i className="material-icons left">shopping_basket</i> <center><Dots color='#fff' /></center></button>
                  ) : (
                    <button className='btn btn-chart onBtnCart' style={{background: (currentCompany.company_color3)}} onClick={addCart}><i className="material-icons left">shopping_basket</i> Masukan Keranjang</button>
                  ) }
                </div>
              </div>
            </div>
          ) : null }

          <div className='add-chart-seaction'>
            <div className="row border-bottom add-chart-seaction-box">
              <div className="col s4 m4">
                <a href={'https://api.whatsapp.com/send?phone='+company.phone1+'&text=Saya%20mau%20bertanya%20produk%20'+product.title} className='btn btn-chat' style={{background: (currentCompany.company_color2)}}><i className="material-icons left">chat</i> Tanya CS</a>
              </div>
              <div className="col s8 m8">
                {
                  currentUser ? (
                    <>
                      {
                        readyOrder ? (
                          <Link to="/carts">
                            <button className='btn btn-chart' style={{background: (currentCompany.company_color3)}} onClick={onShowCart}><i className="material-icons left">shopping_basket</i> Bayar Sekarang</button>
                          </Link>
                        ) : (
                          <button className='btn btn-chart' style={{background: (currentCompany.company_color3)}} onClick={onShowCart}><i className="material-icons left">shopping_basket</i> Masukan Keranjang</button>
                        )
                      }
                    </>
                  ) : (
                    <Link to="/login">
                      <button className='btn btn-chart' style={{background: (currentCompany.company_color3)}} onClick={onShowCart}><i className="material-icons left">shopping_basket</i> Masukan Keranjang</button>
                    </Link>
                  )
                }
              </div>
            </div>
          </div>
        </>
      )}
    </>
    ) : null
  );
}

export default ProductShow;
