import React, { useState, useEffect } from 'react'
import API from './utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";

const CategorySection = ({token}) => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const onClickSearch = keyword => {
    if(window.location.href.includes('/products')){
      window.location.replace(`/products?key=${keyword}`)
    }else{
      history.replace(`/products?key=${keyword}`);
    }
    
  }

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status: true} };

    API.get(`categories`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCategories(data)
        setSpinner(false)
      })
  }

  useEffect(() => {
    loadData('');
  }, []);

  return (
    <div className="row p-top-10 back-grey">
      <div className="col s12 m12">
        <span className='title-card-content'>Kategori</span>
      </div>
      <div className="col s12 m12">
        <div className='scroll-touch'>
          { spinner ? (
            <div className="col s12 m12" >
              <center><Dots color='#444' /></center>
            </div>
          ) : (
            categories.map((p) => {
              return (
                <div className="icon-categories" key={p.id} onClick={e => onClickSearch(p.name)}>
                  <div className="icon-image">
                    <img src={p.image_url}/>
                  </div>
                  <div className='icon-category-name'>{p.name}</div>
                </div>
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}

export default CategorySection