import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { Routes } from './Routes';
import ReactSession from './ReactSession';
import { Helmet } from 'react-helmet';

import './css/materialize.min.css';
import './index.css';

function App() {
  ReactSession.setStoreType("sessionStorage");
  const currentCompany = ReactSession.get("current_company");

  return (
    <Router>
      <div className='App'>
        { currentCompany ? (
          <Helmet>
            <title>{currentCompany ? currentCompany.full_name : 'Mobile App'}</title>
            <meta name="description" content="" />
            <link rel="apple-touch-icon" href={currentCompany.avatar_url} />
            <link rel="icon" href={currentCompany.avatar_url} />
          </Helmet>
        ) : null}
        {Routes}
      </div>
    </Router>
  );
}

export default App;
