import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import HeaderScreen from '../components/HeaderScreen'

const Login = () => {
  const alert = useAlert();
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");

  const [company, setCompany] = useState({});
  const [identifier, setIdentifier] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordType, setPasswordType] = useState("password");

  // Function to toggle the password visibility state
  const toggleShowPassword = () => {
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  };

  const [spinner, setSpinner] = useState(false);

  const loadData = () => {
    const config = {};
    API.get(`get_company`, config )
      .then(res => {
        const data = res.data.data;
        setCompany(data);
        ReactSession.set("current_company", data);
      })
  }

  const actLogin = () => {
    setSpinner(true);
    API.post('sessions', {identifier: identifier, password: password, include: 'company, websetting_galleries, last_package'} )
      .then(res => {
        setSpinner(false);
        ReactSession.set("token", res.data.meta.token);
        ReactSession.set("current_user", res.data.data);
        ReactSession.remove("current_order_count");
        ReactSession.remove("current_order_id");
        history.push("/dashboard");
      })
      .catch(error => {
        setSpinner(false);
        if(error === undefined){
          alert.show('Login Gagal, Terjadi kesalahan di server')
        }else{
          if(error.response.data.meta.status.message !== 'NOT ENOUGH OR TOO MANY SEGMENTS'){
            alert.show(error.response.data.meta.status.message);
          }
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    loadData('');
    if(token && currentUser){
      history.push("/dashboard")
    }
  },[])

  return (
    <>
      { company ? (
        <Helmet>
          <title>{company ? company.full_name : 'Mobile App'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.avatar_url} />
          <link rel="icon" href={company.avatar_url} />
        </Helmet>
      ) : null}

      <div className="row login-form">
        <div className="input-field col s12">
          { company ? (
            <a href='/'>
              <img src={company.company_logo_url} className="login-logo"/>
            </a>
          ) : (
            <h1 className='h1-login'>Login</h1>
          ) }
        </div>
        <div className="input-field input-new col s12">
          <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>account_circle</i>
          <input placeholder='Email or No.Hp' type="text" className="validate" value={identifier} onChange={e => setIdentifier(e.target.value)} />
        </div>
        <div className="input-field input-new col s12">
          <i className="material-icons span-blue prefix" style={{color: (company ? company.company_color1 : '')}}>lock</i>
          <input placeholder='Kata Sandi' type={passwordType} className="validate" value={password} onChange={e => setPassword(e.target.value)} />
          <i className="material-icons span-blue prefix eye" onClick={toggleShowPassword} style={{color: passwordType === 'password' ? '#ccc' : '#000' }}>
            remove_red_eye
          </i>
        </div>
        <div className="box-col">
          { spinner ? (
            <button className="btn waves-effect waves-light btn-large full-width" style={{background: '#1d2c86'}} type="submit" name="action" >
              <center><Dots color='#fff' /></center>
            </button>
          ) : (
            <button className="btn waves-effect waves-light btn-large full-width" style={{background: '#1d2c86'}} type="submit" name="action" onClick={actLogin} >
              Masuk
            </button>
          ) }
        </div>
        <div className="col s12">
          <center className='span-white'>
            <span>Anda Belum Punya Akun ? <Link to="/register" >Daftar Sekarang</Link></span>
            <br/>
            <span>Lupa Kata Sandi ? <Link to="/forgot_password" >Pulihkan Kata Sandi</Link></span>
          </center>
        </div>
      </div>
    </>
  )
}

export default Login