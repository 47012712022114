import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import BottomSheet from '../components/BottomSheet';
import dateFormat from "dateformat";
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Report = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [currentPage, setCurrentPage] = useState('daily_report');

  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [report, setReport] = useState({});
  const [reportChartDaily, setReportChartDaily] = useState([]);
  const [reportChartMonthly, setReportChartMonthly] = useState([]);
  const [reportChartMember, setReportChartMember] = useState([]);
  const [reportChartCity, setReportChartCity] = useState([]);
  const [reportChartProduct, setReportChartProduct] = useState([]);

  let newDate = new Date()
  const [byMonth, setByMonth] = useState(newDate.getMonth()+1);
  const [byYear, setByYear] = useState(newDate.getFullYear());
  const [years, setYears] = useState([]);

  const [offsetMember, setOffsetMember] = useState(1);
  const [pagingMember, setPagingMember] = useState(true);
  const [loadMoreMember, setLoadMoreMember] = useState(true);

  const [offsetCity, setOffsetCity] = useState(1);
  const [pagingCity, setPagingCity] = useState(true);
  const [loadMoreCity, setLoadMoreCity] = useState(true);

  const [offsetProduct, setOffsetProduct] = useState(1);
  const [pagingProduct, setPagingProduct] = useState(true);
  const [loadMoreProduct, setLoadMoreProduct] = useState(true);
  
  const [spinnerOrderDaily, setSpinnerOrderDaily] = useState(true);
  const [spinnerOrderMonthly, setSpinnerOrderMonthly] = useState(true);
  const [spinnerOrderMember, setSpinnerOrderMember] = useState(true);
  const [spinnerOrderCity, setSpinnerOrderCity] = useState(true);
  const [spinnerOrderProduct, setSpinnerOrderProduct] = useState(true);
  const [spinnerSpending, setSpinnerSpending] = useState(true);

  const pages = [
    ['daily_report', 'Report Harian'],
    ['monthly_report', 'Report Bulanan'],
    ['customer_report', 'Report Pelanggan'],
    ['region_report', 'Report Daerah'],
    ['product_report', 'Report Produk']
  ]

  const hashMonth = [
    [1, "Jan"], [2, "Feb"], [3, "Mar"], [4, "Apr"], [5, "May"], [6, "Jun"], [7, "Jul"], [8, "Aug"], [9, "Sep"], [10, "Oct"], [11, "Nov"], [12, "Dec"]
  ]

  const hashYear = () => {
    let i = 2021;
    let arr = [];
    while (i <= newDate.getFullYear()) {
      arr.push(i)
      i++;
    }
    setYears(arr);
  }

  const loadData = (start_date, end_date, by_month, by_year, category) => {    
    let category_select = category ? category : currentPage
    if(category_select === 'daily_report'){
      loadChartDaily(by_month, by_year);
      const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status_payment: 'paid', category_date: 'paid_date', by_month: by_month, by_year: by_year}};
      loadReport(config)
    }else if(category_select === 'monthly_report'){
      loadChartMonthly(by_month, by_year);
      const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status_payment: 'paid', category_date: 'paid_date', by_year: by_year}};
      loadReport(config)
    }else if(category_select === 'customer_report'){
      loadChartMember(start_date, end_date);
    }else if(category_select === 'region_report'){
      loadChartCity(start_date, end_date);
    }else if(category_select === 'product_report'){
      loadChartProduct(start_date, end_date);
    }
  }

  const loadReport = (config) =>{
    API.get(`report_user`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      }).catch(error => {
        console.log(error.response);
      })
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(start_date, searchEndDate, byMonth, byYear);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(searchStartDate, end_date, byMonth, byYear);
  }

  const updateByMonth = (month) => {
    setByMonth(month)
    loadData(searchStartDate, searchEndDate, month, byYear);
  }

  const updateByYear = (year) => {
    setByYear(year)
    loadData(searchStartDate, searchEndDate, byMonth, year);
  }

  const loadPage = (page) => {
    setCurrentPage(page);
    loadData(searchStartDate, searchEndDate, byMonth, byYear, page);
  }

  const loadChartDaily = (by_month, by_year) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: false, category: 'group_by_daily_order', category_date: 'paid_date', by_month: by_month, by_year: by_year }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderDaily(false);
        const data = res.data.report;
        setReportChartDaily(data);
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartMonthly = (by_month, by_year) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: false, category: 'group_by_monthly_order', category_date: 'paid_date', by_year: by_year }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderMonthly(false);
        const data = res.data.report;
        setReportChartMonthly(data);
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartMember = (start_date, end_date) =>{
    setOffsetMember(1);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: true, by_dropship: true, category: 'group_by_customer', category_date: 'paid_date', start_at: start_date, end_at: end_date }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderMember(false);
        const data = res.data.report;
        setReportChartMember(data);
        setLoadMoreMember(false);
        if(res.data.total_pages <= 1){
          setPagingMember(false);
          console.log('No paging')
        }else{
          setPagingMember(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartMemberMore = () =>{
    setLoadMoreMember(true);
    setOffsetMember(offsetMember+1)
    let offset_page = offsetMember+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {page: offset_page, pagination: true, by_dropship: true, category: 'group_by_customer', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderMember(false);
        const data = res.data.report;
        setLoadMoreMember(false);
        setReportChartMember([...reportChartMember, ...data]);
        if(res.data.total_pages === offset_page){
          setPagingMember(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartCity = (start_date, end_date) =>{
    setOffsetCity(1);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: true, category: 'group_by_city_customer', by_dropship: true, category_date: 'paid_date', start_at: start_date, end_at: end_date }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderCity(false);
        const data = res.data.report;
        setReportChartCity(data);
        setLoadMoreCity(false);
        if(res.data.total_pages <= 1){
          setPagingCity(false);
          console.log('No paging')
        }else{
          setPagingCity(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartCityMore = () =>{
    setLoadMoreCity(true);
    setOffsetCity(offsetCity+1)
    let offset_page = offsetCity+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {page: offset_page, pagination: true, by_dropship: true, category: 'group_by_city_customer', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderCity(false);
        const data = res.data.report;
        setLoadMoreCity(false);
        setReportChartCity([...reportChartCity, ...data]);
        if(res.data.total_pages === offset_page){
          setPagingCity(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartProduct = (start_date, end_date) =>{
    setOffsetProduct(1);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: true, category: 'group_by_product', category_date: 'paid_date', start_at: start_date, end_at: end_date }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderProduct(false);
        const data = res.data.report;
        setReportChartProduct(data);
        setLoadMoreProduct(false);
        if(res.data.total_pages <= 1){
          setPagingProduct(false);
          console.log('No paging')
        }else{
          setPagingProduct(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartProductMore = () =>{
    setLoadMoreProduct(true);
    setOffsetProduct(offsetProduct+1)
    let offset_page = offsetProduct+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {page: offset_page, pagination: true, category: 'group_by_product', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate }};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderProduct(false);
        const data = res.data.report;
        setLoadMoreProduct(false);
        setReportChartProduct([...reportChartProduct, ...data]);
        if(res.data.total_pages === offset_page){
          setPagingProduct(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }


  useEffect(() => {  
    if(token === undefined || currentUser === undefined){
      history.push("/login")
    }

    hashYear();
    loadData(searchStartDate, searchEndDate, byMonth, byYear);
    setSpinnerScreen(false);  

  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Report</span>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div class='row m-bottom0 m-top-10'>
            <div className="col s12 select-none-ui p-top0">
              <div className="input-field input-new m-bottom0 m-top0">
                <select className='select' value={currentPage} onChange={e => loadPage(e.target.value)}>
                  <option value=''>Pilih Kategori</option>
                  { pages ? (
                      pages.map((c) => {
                        return (
                          <option key={c[0]} value={c[0]}>{c[1]}</option>
                        )
                      })
                    ) : null
                  }
                </select>
              </div>
            </div>
            <div className="col s12">
              {
                currentPage === 'daily_report' ? (
                  <>
                    { spinnerOrderDaily ? (
                      <div className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </div>
                    ) : (
                      <>
                        <div className='row row-initial m-bottom0'>
                          <div className="col s6 select-none-ui p-top0">
                            <div className="input-field input-new m-bottom0 m-top0">
                              <select className='select' value={byMonth} onChange={e => updateByMonth(e.target.value)}>
                                <option value=''>Pilih Bulan</option>
                                { hashMonth ? (
                                    hashMonth.map((c) => {
                                      return (
                                        <option key={c[0]} value={c[0]}>{c[1]}</option>
                                      )
                                    })
                                  ) : null
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col s6 select-none-ui p-top0">
                            <div className="input-field input-new m-bottom0 m-top0">
                              <select className='select' value={byYear} onChange={e => updateByYear(e.target.value)}>
                                <option value=''>Pilih Tahun</option>
                                { years ? (
                                    years.map((c) => {
                                      return (
                                        <option key={c} value={c}>{c}</option>
                                      )
                                    })
                                  ) : null
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                        { reportChartDaily && reportChartDaily.length > 0 ? (
                          <>
                            {
                              reportChartDaily.map((u, i) => {
                                return (
                                  <div className='list-content' key={i}>
                                    <div>
                                      <span className='list-content-title'>{dateFormat(u.date, "dd-mm-yyyy")}</span>
                                      <br/>
                                      Pesanan <b>{numberFormat(u.count)}</b> | Pembelian Mitra <b>{numberFormat(u.store_price)}</b> | Omset <b>{numberFormat(u.sell_price)}</b>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </>
                          ) : (
                            <div className='blank-message' style={{marginTop:10}}>
                              Data Tidak Ditemukan
                            </div>
                          )
                        }
                      </>
                    )}
                  </>
                ) : currentPage === 'monthly_report' ? (
                  <>
                    { spinnerOrderMonthly ? (
                      <div className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </div>
                    ) : (
                      <>
                        <div className='row row-initial m-bottom0'>
                          <div className="input-field col s12 select-none-ui m-bottom0 m-top0 p-top0">
                            <select className='select' value={byYear} onChange={e => updateByYear(e.target.value)}>
                              <option value=''>Pilih Tahun</option>
                              { years ? (
                                  years.map((c) => {
                                    return (
                                      <option key={c} value={c}>{c}</option>
                                    )
                                  })
                                ) : null
                              }
                            </select>
                          </div>
                        </div>
                        { reportChartMonthly && reportChartMonthly.length > 0 ? (
                          <>
                            {
                              reportChartMonthly.map((u, i) => {
                                return (
                                  <div className='list-content' key={i}>
                                    <div>
                                      <span className='list-content-title'>{dateFormat(u.month, "mmmm, yyyy")}</span>
                                      <br/>
                                      Pesanan <b>{numberFormat(u.count)}</b> | Pembelian Mitra <b>{numberFormat(u.store_price)}</b> | Omset <b>{numberFormat(u.sell_price)}</b>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </>
                          ) : (
                            <div className='blank-message' style={{marginTop:10}}>
                              Data Tidak Ditemukan
                            </div>
                          )
                        }
                      </>
                    )}
                  </>
                ) : currentPage === 'customer_report' ? (
                  <>
                    { spinnerOrderMember ? (
                      <div className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </div>
                    ) : (
                      <>
                        <div className='row row-initial m-bottom0'>
                          <div className="col s6 select-none-ui m-bottom0 m-top0 p-top0 datepicker-ipt">
                            <DatePicker placeholder='Dari Tanggal' value={searchStartDate} onChange={e => updateSearchStartDate( format(e, "yyyy-MM-dd") )}/>
                          </div>
                          <div className="col s6 select-none-ui m-bottom0 m-top0 p-top0 datepicker-ipt">
                            <DatePicker placeholder='Sampai Tanggal' value={searchEndDate} onChange={e => updateSearchEndDate( format(e, "yyyy-MM-dd") )}/>
                          </div>
                        </div>

                        { reportChartMember && reportChartMember.length > 0 ? (
                          <>
                            {
                              reportChartMember.map((u, i) => {
                                return (
                                  <div className='list-content' key={i}>
                                    <div>
                                      <span className='list-content-title'>{u.customer_name} {u.customer_phone}</span>
                                      <br/>
                                      Pesanan <b>{numberFormat(u.order_count)}</b> | Pembelian Mitra <b>{numberFormat(u.store_price)}</b> | Omset <b>{numberFormat(u.sell_price)}</b>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </>
                          ) : (
                            <div className='blank-message' style={{marginTop:10}}>
                              Data Tidak Ditemukan
                            </div>
                          )
                        }

                        {pagingMember ? (
                          <div onClick={loadChartMemberMore} class='load-more-btn-white'>
                            {loadMoreMember ? (
                              <center><Dots color='#444' /><br/></center>
                            ) : null}
                            SELANJUTNYA
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                ) : currentPage === 'region_report' ? (
                  <>
                    { spinnerOrderCity ? (
                      <div className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </div>
                    ) : (
                      <>
                        <div className='row row-initial m-bottom0'>
                          <div className="col s6 select-none-ui m-bottom0 m-top0 p-top0 datepicker-ipt">
                            <DatePicker placeholder='Dari Tanggal' value={searchStartDate} onChange={e => updateSearchStartDate( format(e, "yyyy-MM-dd") )}/>
                          </div>
                          <div className="col s6 select-none-ui m-bottom0 m-top0 p-top0 datepicker-ipt">
                            <DatePicker placeholder='Sampai Tanggal' value={searchEndDate} onChange={e => updateSearchEndDate( format(e, "yyyy-MM-dd") )}/>
                          </div>
                        </div>

                        { reportChartCity && reportChartCity.length > 0 ? (
                          <>
                            {
                              reportChartCity.map((u, i) => {
                                return (
                                  <div className='list-content' key={i}>
                                    <div>
                                      <span className='list-content-title'>{u.city}, {u.province}</span>
                                      <br/>
                                      Pesanan <b>{numberFormat(u.order_count)}</b> | Pembelian Mitra <b>{numberFormat(u.store_price)}</b> | Omset <b>{numberFormat(u.sell_price)}</b>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </>
                          ) : (
                            <div className='blank-message' style={{marginTop:10}}>
                              Data Tidak Ditemukan
                            </div>
                          )
                        }

                        {pagingCity ? (
                          <div onClick={loadChartCityMore} class='load-more-btn-white'>
                            {loadMoreCity ? (
                              <center><Dots color='#444' /><br/></center>
                            ) : null}
                            SELANJUTNYA
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                ) : currentPage === 'product_report' ? (
                  <>
                    { spinnerOrderProduct ? (
                      <div className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </div>
                    ) : (
                      <>
                        <div className='row row-initial m-bottom0'>
                          <div className="col s6 select-none-ui m-bottom0 m-top0 p-top0 datepicker-ipt">
                            <DatePicker placeholder='Dari Tanggal' value={searchStartDate} onChange={e => updateSearchStartDate( format(e, "yyyy-MM-dd") )}/>
                          </div>
                          <div className="col s6 select-none-ui m-bottom0 m-top0 p-top0 datepicker-ipt">
                            <DatePicker placeholder='Sampai Tanggal' value={searchEndDate} onChange={e => updateSearchEndDate( format(e, "yyyy-MM-dd") )}/>
                          </div>
                        </div>

                        { reportChartProduct && reportChartProduct.length > 0 ? (
                          <>
                            {
                              reportChartProduct.map((u, i) => {
                                return (
                                  <div className='list-content' key={i}>
                                    <div>
                                      <span className='list-content-title'>({u.code}) {u.title}</span>
                                      <br/>
                                      Pesanan <b>{numberFormat(u.order_count)}</b> | Pembelian Mitra <b>{numberFormat(u.store_price)}</b> | Omset <b>{numberFormat(u.sell_price)}</b>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </>
                          ) : (
                            <div className='blank-message' style={{marginTop:10}}>
                              Data Tidak Ditemukan
                            </div>
                          )
                        }

                        {pagingProduct ? (
                          <div onClick={loadChartProductMore} class='load-more-btn-white'>
                            {loadMoreProduct ? (
                              <center><Dots color='#444' /><br/></center>
                            ) : null}
                            SELANJUTNYA
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                ) : null
              }
            </div>
          </div>

        </>
      )}
      <BottomSheet/>
    </>
    ) : null
  );
}

export default Report;
