import React, { useEffect, useState} from 'react';
import Header from '../components/Header'
import CategorySection from '../components/CategorySection'
import M from 'materialize-css';
import ProductSection from '../components/ProductSection';
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import { useAlert } from 'react-alert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import BottomSheet from '../components/BottomSheet';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function copyCodeToClipboard(link){
  navigator.clipboard.writeText(link);
  alert('Copy '+link)
}

const LandingPages = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerScreen, setSpinnerScreen] = useState(true);

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      alert('Copy Link Berhasil');
    } catch (err) {
      alert('Failed to copy!');
    }
  };

  const loadData = (keyword) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'landing_press'  }};
    API.get(`request_landing_pages`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', include: 'landing_press' } };
    
    API.get(`request_landing_pages`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`request_landing_pages/${id}`, config )
      .then(res => {
        loadData();
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    loadData(search);
  }

  useEffect(() => {  
    if(token === undefined || currentUser === undefined){
      history.push("/login")
    }

    loadData();
    setSpinnerScreen(false);  

  },[])

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Landing Page</span>
            <ul className="right">
              <li>
                <Link to="/request_landing_pages" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>add_box</i></Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className='sidenav-overlay'></div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          <div className='row m-bottom0'>
            <div className="col s12">
              <div className="input-field search-on-list m-top0">
                <input className="input-back" autocomplete="off" type="search" value={search} onChange={e => updateSearch(e.target.value)}/>
                <label className="label-icon" for="search"><i className="material-icons material-icon-search" style={{color: (currentCompany.company_color1)}}>search</i></label>
              </div>
              <>
                { spinner ? (
                  <div className='text-center'>
                    <div className='loadLoading'><Dots color='#444' /></div>
                  </div>
                ) : (
                  <>
                    { data && data.length > 0 ? (
                      <>
                        {
                          data.map((u) => {
                            return (
                              <div className='list-content' key={u.id}>
                                <div className="avatar rounded-circle mr-3" href="#" onClick={(e) => e.preventDefault()}>
                                  <img alt="..." src={u.landing_press.brand_image} />
                                </div>
                                <div className='list-content-text' style={{paddingTop: 0}}>
                                  <b>{u.landing_press.title}</b>
                                  <br/>
                                  LP Brand {u.landing_press.brand_name} . WA {u.contact}
                                  <br/>
                                  {/* <button onClick={() => copyToClipBoard(u.link_lp)} className='btn btn-default btn-download'>Copy to Link Landing Page</button> */}
                                  <input value={u.link_lp} style={{fontSize: 9, textTransform: 'inherit', width: 190, marginTop: 4, borderRadius: 10, background: '#f5f5f5', textAlign: 'center'}}/>
                                </div>
                                <div className='icon-act-content'>
                                  <Link to={`/edit_request_landing_page/${u.id}`} ><i className="material-icons material-icon-search" style={{color: '#ccc'}}>settings</i></Link>
                                  <br/>
                                  <i onClick={(e) => deletePost(u.id)} className="material-icons material-icon-search" style={{color: '#ccc', cursor: 'pointer'}}>cancel</i>
                                </div>
                              </div>
                            )
                          })
                        }
                      </>
                      ) : (
                        <div className='blank-message'>
                          Data Tidak Ditemukan
                        </div>
                      )
                    }
                  </>
                )}
                {paging ? (
                  <div onClick={loadMoreData} className='load-more-btn-white'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                ) : null}
              </>
            </div>
          </div>
        </>
      )}
      <BottomSheet/>
    </>
    ) : null
  );
}

export default LandingPages;
