import React, { useEffect, useState, useParams } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import HeaderScreen from '../components/HeaderScreen'
import ScreenLoading from '../components/ScreenLoading'
import M from 'materialize-css';
import { DatePicker, Autocomplete } from "react-materialize";
import { format } from "date-fns";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import BottomSheet from '../components/BottomSheet';
import {
  silverIcon,
  goldIcon,
  platinumIcon,
  idCard
} from '../assets'

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


const EditProfile = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const alert = useAlert();
  const history = useHistory();
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [data, setData] = useState({});
  const [report, setReport] = useState({});
  const [reportDownline, setReportDownline] = useState({});
  const [copySuccess, setCopySuccess] = useState('');


  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status_payment: 'paid', include: 'last_package, downline_count'} };

    API.get(`report_user`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_reff_id: currentUser.id, by_status_payment: 'paid'} };
    API.get(`report_user`, config2)
    .then(res => {
      const data = res.data.report;
      setReportDownline(data)
    })

    API.get(`sessions`, config )
      .then(res => {
        setSpinnerScreen(false);
        const data = res.data.data;
        setData(data);
        let elems = document.getElementById("sel");
        let instances = window.M.FormSelect.init(elems, {});
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  }


  useEffect(() => {
    if(token === undefined || currentUser === undefined){
      history.push("/login")
    }
    
    loadData();

    let elSide = document.querySelectorAll('.sidenav-overlay');
    let elBody = document.querySelectorAll('body');
    elSide.forEach((item: any) => {
      item.style.display = 'none';
    })
    elBody.forEach((item: any) => {
      item.style.overflow = 'auto';
    })

    document.addEventListener('DOMContentLoaded', function() {
      let elems = document.querySelectorAll('.datepicker');
      let instances = M.Datepicker.init(elems);
    });

    let elems = document.getElementById("sel");
    let instances = window.M.FormSelect.init(elems, {});

  }, []);

  return (
    currentCompany ? (
    <>
      <HeaderScreen title="Profil"/>
      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          {
            data.verified_id_card == false ? (
              <div className='no-verified' style={{color:'red'}}>
                Identitas & Nomor Rekening Anda Belum di Verifikasi!<br/> <Link to="/edit_payment">Verifikasi Sekarang</Link>
              </div>
            ) : null
          }
          <div className="row bg-grey m-bottom0 box-profile-info" style={{paddingTop:0}}>
            <div>
              <img className='idcard-package' src={idCard}/>
              <div className='idcard-company-logo'>
                {
                    currentCompany ? (
                      <img src={currentCompany.company_icon_url}/>
                    ) : null
                }
              </div>
              <div className='idcard-package-avatar'>
                <div className='avatar' style={{marginBottom:20}}>
                  <img src={data.avatar_url}/>
                </div>
                <center className='package-card'>
                  {
                    data.last_package && data.last_package.title.includes('Silver') ? (
                      <img src={silverIcon} width='60'/>
                    ) : data.last_package && data.last_package.title.includes('Gold') ? (
                      <img src={goldIcon} width='60'/>
                    ) : data.last_package && data.last_package.title.includes('Platinum') ? (
                      <img src={platinumIcon} width='60'/>
                    ) : null
                  }
                </center>
              </div>
              <div className='idcard-package-package'>
                <center className=''>
                  <div>
                    {
                      data.level_type ? (
                        <>
                          <b className='label-level-type'>{data?.level_type_txt?.toUpperCase()}</b>
                          <br/>
                        </>
                      ) : null
                    }
                    <span style={{fontSize:20, fontWeight:'bold'}}>{data.full_name}</span>
                    {
                      data.have_order_paid ? (
                        <>
                          <br/>
                          {data.code}
                        </>
                      ) : null
                    }
                    <br/>
                    <br/>
                    {data.email}
                    <br/>
                    {data.phone1}
                    <br/>
                    {data.province}
                  </div>
                </center>
              </div>
            </div>
          </div>

          {/* <div className="row border-bottom">
            <div className="col s12 m12">
              <div className='box-bank-number' style={{textAlign: 'left', background: 'white', margin: 0}}> 
                <small>
                  Silhkan Hubungi Layanan Customer Support Kami
                </small>
                <br/>
                <div>
                  <br/>
                  <a href={'https://api.whatsapp.com/send?phone='+currentCompany.phone1+'&text=Saya%20mau%20upgrade%20akun%20mitra%20dengan%20id%20'+data.code+'%20Nama%20'+data.full_name} className='btn' style={{width: '100%', background: (currentCompany.company_color2)}}>Upgrade Akun Mitra</a>
                  <br/>
                </div>
              </div>
            </div>
          </div> */}

          {
            data.have_order_paid ? (
              <div className="row bg-grey m-bottom0" style={{marginTop:20}}>
                <div className="col s12 pb-10">
                  <div className="profile-info-table space-mb--40 reff">
                    <div className="profile-info-block">
                      <div className="profile-info-block__title">Kode Referral Anda</div>
                      <div className="profile-info-block__value">
                        <b style={{fontSize: 15}}>{data.code}</b>
                        <button className='copy' onClick={() => copyToClipBoard(data.code)}>
                          Copy
                        </button>
                        &nbsp;{copySuccess}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }

          <div className="row bg-grey m-bottom0">
            <div className="col s12 pb-10">
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Jumlah Transaksi</div>
                  <div className="profile-info-block__value"><b>{report.order_store ? currencyFormat(report.order_store) : numberFormat(0)}</b></div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Jumlah Pesanan</div>
                  <div className="profile-info-block__value"><b>{report.order_count ? numberFormat(report.order_count) : numberFormat(0)}</b></div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Transaksi Jaringan Member</div>
                  <div className="profile-info-block__value"><b>{reportDownline.order_store ? currencyFormat(reportDownline.order_store) : numberFormat(0)}</b></div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Jumlah Jaringan</div>
                  <div className="profile-info-block__value">
                    <b><Link to="/downline">{data.downline_count ? numberFormat(data.downline_count) : numberFormat(0)}</Link></b>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bg-grey m-bottom0">
            <div className="col s12 pb-10">
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Ubah Kata Sandi</div>
                  <div className="profile-info-block__value">
                    <Link to="/edit_password" ><i className="material-icons" style={{color: (currentCompany.company_color1)}}>settings</i></Link>  
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bg-grey m-bottom0">
            <div className="col s12 pb-10">
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Ubah Profil</div>
                  <div className="profile-info-block__value">
                    <Link to="/edit_profile" ><i className="material-icons" style={{color: (currentCompany.company_color1)}}>settings</i></Link>  
                  </div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Nama Lengkap</div>
                  <div className="profile-info-block__value">{data.full_name}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Email</div>
                  <div className="profile-info-block__value">{data.email}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Bergabung Sejak</div>
                  <div className="profile-info-block__value">{data.created_at}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">No.HP/Whatsapp</div>
                  <div className="profile-info-block__value">{data.phone1 ? data.phone1 : null}</div>
                </div>
              </div>
              {/* <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Kontak 2</div>
                  <div className="profile-info-block__value">{data.phone2 ? data.phone2 : null}</div>
                </div>
              </div> */}
              {/* <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Jenis Kelamin</div>
                  <div className="profile-info-block__value">{data.gender}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Tanggal Lahir</div>
                  <div className="profile-info-block__value">{data.birthday}</div>
                </div>
              </div> */}
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Alamat Domisili</div>
                  {
                    data.district ? (
                      <div className="profile-info-block__value">{data.district}, {data.city}, {data.province}</div>
                    ) : (
                      <div className="profile-info-block__value">Belum Ditentukan</div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="row bg-grey m-bottom0">
            <div className="col s12">
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">No Rekening Reguler</div>
                  <div className="profile-info-block__value">
                    <Link to="/edit_payment" ><i className="material-icons" style={{color: (currentCompany.company_color1)}}>settings</i></Link>  
                  </div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">Nama Bank</div>
                  <div className="profile-info-block__value">{data.payment_bank_name ? data.payment_bank_name : 'Belum di Isi'}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">KCP Kota</div>
                  <div className="profile-info-block__value">{data.payment_bank_kcp ? data.payment_bank_kcp : 'Belum di Isi'}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">No Rekening</div>
                  <div className="profile-info-block__value">{data.payment_bank_number ? data.payment_bank_number : 'Belum di Isi'}</div>
                </div>
              </div>
              <div className="profile-info-table space-mb--40">
                <div className="profile-info-block">
                  <div className="profile-info-block__title">NO.Kartu Identitas</div>
                  <div className="profile-info-block__value">{data.id_card_number}</div>
                </div>
              </div>
            </div>
          </div>

          <BottomSheet/>
        </>
      )}
    </>
    ) : null
  )
}

export default EditProfile