import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import ReactSession from '../ReactSession';

const HeaderScreen = ({title}) => {
  const currentCompany = ReactSession.get("current_company");
  const history = useHistory();

  return (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany?.themeplate === 'App Theme 2' ? currentCompany?.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/dashboard" ><i className="material-icons" style={{color: (currentCompany?.themeplate === 'App Theme 2' ? 'white' : currentCompany?.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany?.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">{title}</span>
          </div>
        </nav>
      </div>
    </>
  )
}

HeaderScreen.defaultProps = {
  title: 'Default'
}

export default HeaderScreen
