import React, { useEffect, useState, useParams } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import M from 'materialize-css';
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';

const EditPayment = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const alert = useAlert();
  const history = useHistory();
  const [data, setData] = useState({});
  const [paymentBankNumber, setPaymentBankNumber] = useState('');
  const [paymentBankKcp, setPaymentBankKcp] = useState('');
  const [paymentBankName, setPaymentBankName] = useState('');
  const [idCardNumber, setIdCardNumber] = useState('');
  const [idCardFile, setIdCardFile] = useState();
  const [idCardSrc, setIdCardSrc] = useState();
  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [spinnerImg, setSpinnerImg] = useState(false);

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`} };

    API.get(`sessions`, config )
      .then(res => {
        const data = res.data.data;
        setData(data);
        setPaymentBankName(data.payment_bank_name);
        setIdCardNumber(data.id_card_number);
        setIdCardSrc(data.id_card_url)
        setPaymentBankName(data.payment_bank_name);
        setPaymentBankNumber(data.payment_bank_number);
        setPaymentBankKcp(data.payment_bank_kcp);
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const changeData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`} };

    const fd = new FormData();
    if(idCardFile){
      fd.append('id_card', idCardFile, idCardFile.name);
    }
    fd.append('payment_bank_name', paymentBankName);
    fd.append('payment_bank_kcp', paymentBankKcp);
    fd.append('payment_bank_number', paymentBankNumber);
    fd.append('id_card_number', idCardNumber);

    API.put(`users/${currentUser.id}/change_payment`, fd, config )
      .then(res => {
        alert.success('Data Berhasil Diubah')
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const handleIdCardSelect = (e) => {
    setIdCardSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setIdCardFile(files);
  }

  useEffect(() => {
    if(token === undefined || currentUser === undefined){
      history.push("/login")
    }

    loadData();
    setSpinnerScreen(false);

    // let elSide = document.querySelectorAll('.sidenav-overlay');
    // let elBody = document.querySelectorAll('body');
    // elSide.forEach((item: any) => {
    //   item.style.display = 'none';
    // })
    // elBody.forEach((item: any) => {
    //   item.style.overflow = 'auto';
    // })
  }, []);

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/profile" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Ubah Nomor Pembayaran</span>
          </div>
        </nav>
      </div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <>
          {
            data && data.verified_id_card == false ? (
              <div className='no-verified' style={{position: 'relative', color: 'red'}}>
                Jika anda sudah mengisi ini dengan baik, untuk verifikasi no.rekening & kartu identitas silahkan hubungi admin kami<br/>  <a href={'https://api.whatsapp.com/send?phone='+currentCompany.phone1+'&text=Saya%20mau%20verifikasi%20rekening%20dan%20identitas%20'+currentUser.email}> Hubungi Customer Service</a>
              </div>
            ) : null
          }
          <div className="row">
            <div className="m-top-10 col s12" style={{paddingBottom:0}}>
              <div className="input-field col s12 m-bottom0 m-top0 p-top0 select-none-ui">
                <label className='select-label'>Nama Bank</label>
                <div className="input-field input-new">
                  <select className='select' value={paymentBankName} onChange={e => setPaymentBankName(e.target.value)} >
                    <option value="">Pilih Bank</option>
                    <option value="BCA">BCA</option>
                    <option value="BNI">BNI</option>
                    <option value="MANDIRI">MANDIRI</option>
                    <option value="BRI">BRI</option>
                    <option value="BSI">BSI</option>
                    <option value="MUAMALAT">MUAMALAT</option>
                    <option value="CIMB Niaga">CIMB Niaga</option>
                    <option value="DANAMON">DANAMON</option>
                    <option value="BJB">BJB</option>
                    <option value="BTPN">BTPN</option>
                  </select>
                </div>
              </div>
              <div className="col s12" style={{paddingBottom:0}}>
                <label>KCP Kota</label>
                <div className="input-field input-new">
                  <input id="icon_prefix" placeholder='KCP Kota' className="input-back" value={paymentBankKcp} onChange={e => setPaymentBankKcp(e.target.value)} />
                </div>
              </div>
              <div className="col s12" style={{paddingBottom:0}}>
                <label>No Rekening</label>
                <div className="input-field input-new">
                  <input id="icon_prefix" placeholder='Masukan Nomor Rekening' className="input-back" value={paymentBankNumber} onChange={e => setPaymentBankNumber(e.target.value)} />
                </div>
              </div>
              <div className="col s12" style={{paddingBottom:0}}>
                <label>No Identitas/KTP</label>
                <div className="input-field input-new">
                  <input placeholder='No Identitas/KTP' type="text" className="input-back" value={idCardNumber} onChange={e => setIdCardNumber(e.target.value)} disabled={data.verified_id_card ? true : false}/>
                </div>
              </div>
              <div className="col s12" style={{paddingBottom:0}}>
                <div className='input-field input-new'>
                  <div className='id-card'>
                    <div className="form-img-select">
                      <div class='avatar-user'>
                        {
                          !data.verified_id_card ? (
                            <ImageUpload
                              handleImageSelect={handleIdCardSelect}
                              imageSrc={idCardSrc}
                              setImageSrc={setIdCardSrc}
                              style={{
                                width: 300,
                                height: 190
                              }}
                            />
                          ) : (
                            <img src={idCardSrc} style={{width: '100%'}}/>
                          )
                        }
                      </div>
                    </div>
                    <p align='center'>Upload Tanda Pengenal KTP/SIM/Passport</p>
                  </div>
                </div>
              </div>
              <div className="col s12">
                <button className="btn waves-effect waves-light btn-large full-width" style={{background: (currentCompany.company_color3)}} type="submit" name="action" onClick={changeData} >
                  Simpan Perubahan
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
    ) : null
  )
}

export default EditPayment