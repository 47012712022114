import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert'
import API from '../components/utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import HeaderScreen from '../components/HeaderScreen';
import ScreenLoading from '../components/ScreenLoading';
import M from 'materialize-css';

const EditRequestLandingPage = () => {
  const {id} = useParams();
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const alert = useAlert();
  const history = useHistory();

  const [landingPressId, setLandingPressId] = useState('');
  const [contact, setContact] = useState(currentUser.phone1);
  const [pixelCode, setPixelCode] = useState('');
  const [scriptCode, setScriptCode] = useState('');

  const [themes, setThemes] = useState([]);
  const [theme, setTheme] = useState({});
  const [data, setData] = useState({});

  const [spinnerScreen, setSpinnerScreen] = useState(true);

  const loadData = () => {   
    const config = { headers: { Authorization: `Bearer ${token}`}, params: { include: 'landing_press' }  };

    API.get(`landing_presses`, config)
      .then(res => {
        const data = res.data.data;
        setThemes(data)

        API.get(`request_landing_pages/${id}`, config)
          .then(res => {
            const data = res.data.data;
            setData(data)
            setLandingPressId(data.landing_press_id)
            setContact(data.contact)
            setPixelCode(data.pixel_code)
            setScriptCode(data.script_code)
            loadTheme(data.landing_press_id)
          })
      })

  }

  const loadTheme = (landing_press_id) => {   
    setLandingPressId(landing_press_id);
    const config = { headers: { Authorization: `Bearer ${token}`}  };
    API.get(`landing_presses/${landing_press_id}`, config)
      .then(res => {
        const data = res.data.data;
        setTheme(data)
      })
  }

  const actPost = () => {
    const config = { headers: { Authorization: `Bearer ${token}`} };

    API.put(`request_landing_pages/${id}`, {landing_press_id: landingPressId, contact: contact, pixel_code: pixelCode, script_code: scriptCode}, config )
      .then(res => {
        alert.success('Landing Page Berhasil Diubah')
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined || currentUser === undefined){
      history.push("/login")
    }
    
    loadData();
    setSpinnerScreen(false);
  }, []);

  return (
    currentCompany ? (
    <>
      <div className="navbar-fixed navbar-fixed-50">
        <nav className="nav-screen" style={{ background: (currentCompany.themeplate === 'App Theme 2' ? currentCompany.company_color2 : '' ) }}>
          <div className="nav-wrapper">
            <ul className="left">
              <li>
                <Link to="/landing_pages" ><i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : currentCompany.company_color1 ) }}>keyboard_backspace</i></Link>
              </li>
            </ul>
            <span style={{color: (currentCompany.themeplate === 'App Theme 2' ? 'white' : '' ) }} className="brand-logo">Ubah Landing Page</span>
          </div>
        </nav>
      </div>

      { spinnerScreen ? (
        <ScreenLoading/>
      ) : (
        <div className="row">
          <div className="m-top-10 col s12">
            <div className="input-field col s12 m-bottom0 m-top0 p-top0 select-none-ui">
              <label className='select-label'>Pilih Landing Page Brand</label>
              <select className='select' value={landingPressId} onChange={e => loadTheme(e.target.value)} >
                <option value=''>Pilih Landing Page</option>
                { themes.map((theme) => {
                  return (
                    <option key={theme.id} value={theme.id}>{theme.title}</option>
                  )
                })}
              </select>
            </div>

            { theme && theme.id > 0 ? (
                <div className="col s12">
                  <div className=''>
                    <img src={theme.banner_section1_url} style={{width: '100%'}}/>
                  </div>
                </div>
              ) : null
            }

            <div className="col s12">
              <label className="m-bottom10">Isi No Whatsapp (Untuk Menerima Pesanan)</label>
              <input placeholder={currentUser.phone1} className="input-back" value={contact} onChange={e => setContact(e.target.value)} />
            </div>
            <div className="col s12">
              <label className="m-bottom10">Pixel Kode FB</label>
              <input placeholder='Masukan Kode Pixel Jika Ada' className="input-back" value={pixelCode} onChange={e => setPixelCode(e.target.value)} />
            </div>
            <div className="col s12">
              <button className="btn waves-effect waves-light btn-large full-width" style={{background: (currentCompany.company_color3)}} type="submit" name="action" onClick={actPost} >
                Simpan
              </button>
            </div>
          </div>
        </div>
      )}
    </>
    ) : null
  )
}

export default EditRequestLandingPage